import {SET_REGION_KEY} from './actions';

type Action = {
    type: string;
    regionKey: string;
}

export default (state = 'World', action: Action): any => {
    switch (action.type) {
        case SET_REGION_KEY:
            return action.regionKey;
        default:
            return state;
    }
}