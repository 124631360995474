/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

type DrugDosageType = {
    data: any;
}

const DrugDosage: React.FC<DrugDosageType> = ({data}) => {
    const [lgShow, setLgShow] = useState<boolean>(false)
    
    return (
        <>
            <p className="diRel">{data.name}</p>
            <span className="diReadMore" data-testid="diReadMore" onClick={(): void=>setLgShow(true)}>READ MORE &gt;</span>
            <p>Source: Drugs.com</p>
            <Modal
                size="lg"
                show={lgShow}
                onHide={(): void => setLgShow(false)}
            >
                <Modal.Header closeButton>
                    <div>
                        <p className="diRel">{data.name}</p>
                    </div>
                </Modal.Header>
                <Modal.Body >
                    {data.applied_to &&
                        <div className="alert alert-info mb-2">{data.applied_to}</div>
                    }
                    <div className="mb-2" dangerouslySetInnerHTML={{ __html: data.content }} />
                    <a href={data.link} className="my-2 btn btn-sm btn-outline-info" target="_blank" rel="noopener noreferrer">View Source</a>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DrugDosage;
