/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';

import './QuestionBubble.css';

type QuestionBubbleProps = {
    content: any;
    fullpage: boolean;
    isMobile: boolean;
}

const QuestionBubble: React.FC<QuestionBubbleProps> = ({content, fullpage, isMobile}) => {
    
    const questionBubbleStyle = {
        width: isMobile ? '90%' : fullpage ? '270px' : '60%',
        transition: 'all 0.5s',
        marginRight: isMobile ? '0' : fullpage ? '20px' : '30px',
    } as React.CSSProperties;

    return (
        <div className="questionBubble" style={questionBubbleStyle}>
            <p>{content}</p>
         </div>
    );
}

export default QuestionBubble;
