/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useRef, useCallback, useEffect} from 'react';

import {getCategory, getBackgroundColor} from '../../utils/constants';

import Close from '../../images/svg/Close.svg';
import './DrugInteraction.css';

type DoctorsProps = {
    data: any;
}

const DrugInteraction: React.FC<DoctorsProps> = ({data}) => {
    const [show, setShow] = useState(false);
    const innerRef = useRef<HTMLDivElement>(null);
    const [answers, setAnswers]  = useState<any[]>([]);
    const [answerSelected, setAnswerSelected] = useState<any>(null);

    useEffect(()=>{
        setAnswers(data.data);
    }, [data])

    const hideModal = useCallback(()=>{
        if (!show || !innerRef.current) return;
        innerRef.current.classList.add("hideModal");
        setTimeout(()=>{
            setShow(false);
            setAnswerSelected(null)
        }, 290)
    }, [show, innerRef, setShow, setAnswerSelected])

    const showAnswer = useCallback((answer)=>{
        setAnswerSelected(answer);
        setShow(true);
    }, [setAnswerSelected])

    return (
        <>
            <div className="drsIntroDiv">
                <h6 className="youAsked">YOU ASKED</h6>
                <h3 className="lsisQuestion">{data.question}</h3>
            </div>

            {answers &&
                <div className="diDiv">
                    <div className="row">
                        {answers.map((answer, idx)=>{
                            return (
                                <div className="col-lg-4" key={idx}>
                                    <div className="diAnswerDiv">
                                        <div className="diAnswer">
                                            <div className="diAnswerLabel" style={{backgroundColor: getBackgroundColor(answer.type)}}>{getCategory(answer.type)}</div>
                                            <h2 className="diAnswerTitle">{answer.drug} &lt;&gt; {answer.name}</h2>
                                            <p className="diAnswerEx">{answer.apply}</p>
                                            <button className="btn btn-link" data-testid={"btn-"+idx} onClick={()=>showAnswer(answer)}>Read More &gt;</button>
                                        </div>
                                        <hr className="diHr" />
                                        <p className="diSource">Source: Drugs.com</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }

            {show && 
                <div className="modalBackDrop">
                    <div className="answerModal" ref={innerRef}>
                        <div className="modalContent">
                            <div className="diAnswerLabel" data-testid="diAnswerLabel" style={{backgroundColor: getBackgroundColor(answerSelected.type)}}>{getCategory(answerSelected.type)}</div>
                            <h2 className="diAnswerTitle">{answerSelected.drug} &lt;&gt; {answerSelected.name}</h2>
                            <p className="diAnswerEx">{answerSelected.apply}</p>
                            <p className="diContent">
                                {answerSelected.content.split('\n').map((item: string, key: number) => {
                                    return <span key={key}>{item}<br/></span>
                                })}
                            </p>
                            {answerSelected.refs && answerSelected.refs.length > 0 &&
                                (<><h3 className="diRefs">References</h3><ol>{answerSelected.refs.map((r: string, idx: number)=>{return (<li key={idx}>{r}</li>)})}</ol></>)
                            }
                        </div>
                        <div className="text-center closeBtn" onClick={hideModal}>
                            <img src={Close} alt="close" />
                            <p className="feedBackBoxTag">Close</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default DrugInteraction;
