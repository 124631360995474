import {SET_LIMIT} from './actions';

type Action = {
    type: string;
    checked: boolean;
}

export default (state = false, action: Action): any => {
    switch (action.type) {
        case SET_LIMIT:
            let val = "0";
            if (action.checked) {
                val = "1";
            }
            localStorage.setItem("limit", val);
            return action.checked;
        default:
            return localStorage.getItem("limit") === "1"
    }
}
  