/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";

import {setResult} from '../reducers/chatActions'

import Chat from '../components/Chat/Chat';
import Result from '../components/Result/Result';
import {logout} from '../reducers/actions';
import { getAuth, setRegions, setRegionKey } from '../reducers/actions';
import requests from '../utils/requests';

type MainProps = {
  fullPage?: boolean;
  page?: string;
}

const Main: React.FC<MainProps> = ({fullPage, page}) => {
    const [isMobile, setIsMobile] = useState<boolean>(false)
    const username = useSelector<any,any>(state=> state.auth.username);
    const [fullpage, setFullpage] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleWindowSizeChange = useCallback((): void => {
      setIsMobile(window.innerWidth <= 760)
    }, [])

    useEffect(()=>{
      handleWindowSizeChange();

      window.addEventListener('resize', handleWindowSizeChange);

      return (): void =>{
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }, [handleWindowSizeChange])

    useEffect(() => {
      const updateGlobalRegions = async () => {
        const { data } = await requests.getUserProfile();
        dispatch(setRegions(data.data.global_regions));
        if (data.data.region) {
          dispatch(setRegionKey(data.data.region))
        } else {
          dispatch(setRegionKey('World'))
          history.replace('/region');
          return;  
        }
      }
      updateGlobalRegions()
    }, [dispatch, history])

    const logoutHandler = useCallback((event?: MouseEvent): void => {
      if (event) event.preventDefault();
      dispatch(logout());
    }, [dispatch])

    useEffect(()=>{
      const auth = getAuth()

      if (!auth.username || parseInt(auth.expire) < new Date().getTime()) {
        logoutHandler()
        history.replace('/login');
        return;
      } 

      if (fullPage === true || fullPage === false) {
        setFullpage(fullPage);
        if (page && page==="resources") {
          dispatch(setResult({type: 'resources'}))
        }
      }
    }, [username, history, fullPage, page, dispatch, logoutHandler])

    const fullPageHandler = useCallback((status: boolean)=>{
      setFullpage(status);
    }, []);

    let isFullPage = fullpage;
    if (isMobile) {
      isFullPage = true
    }
    
    return (
      <>
        <Chat fullpage={isFullPage} isMobile={isMobile} OnLogOut={logoutHandler} onFullChange={(ns: boolean): void=>fullPageHandler(ns)} />
        {!isFullPage && <Result OnLogOut={logoutHandler} />}
      </>
    );
}

export default Main;
