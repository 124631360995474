/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Dropdown, Form} from 'react-bootstrap';
import Noty from 'noty';  

import {Bubble} from '../../utils/types';
import {updateRegionKey, setRegions} from '../../reducers/actions'
import {addAnswer, editAnswer, addQuestion, addTags, removeTags, reset} from '../../reducers/chatActions';
import {TAGS, WELCOME, INTRO} from '../../utils/constants'

import AnswerBubble from '../AnswerBubble/AnswerBubble';
import QuestionBubble from '../QuestionBubble/QuestionBubble';
import TagsBubble from '../TagsBubble/TagsBubble';

import Lsi from '../Mobile/Lsi';
import Map from '../Mobile/Map';
import DoctorInfo from '../Mobile/DoctorInfo';
import DrugInteraction from '../Mobile/DrugInteraction';
import DrugDosage from '../Mobile/DrugDosage';
import HelpPage from '../Mobile/HelpPage';

import request from '../../utils/requests';

import Logo from '../../images/svg/Logo-dark.svg';
import PhotoBig from '../../images/photo-big.png';
import Send from '../../images/svg/Send.svg';
import Cancel from '../../images/svg/Cancel.svg';
import Speak from '../../images/svg/Speak.svg';
import {REGIONAL_ENABLED, SOURCES_ENABLED} from '../../utils/constants';
import {regions, globalSources} from '../../utils/helpers';

import './Chat.css';

const SpeechRecognition = window.SpeechRecognition || (window as any).webkitSpeechRecognition
let recognition: any = null;

if (SpeechRecognition) {
    recognition = new SpeechRecognition()
    recognition.continuous = true
    recognition.lang = 'en-US' 
}

type ChatProps = {
    fullpage: boolean;
    isMobile: boolean;
    onFullChange: Function;
    OnLogOut: any;
}

const Chat: React.FC<ChatProps> = ({fullpage, isMobile, onFullChange, OnLogOut}) => {
    const chats = useSelector<any,any>(state=>state.chats) as [];
    const global_regions = useSelector<any,any>(state=> state.global_regions);
    const regionKey = useSelector<any, any>(state=>state.regionKey);
    const [searchWord, setSearchWord] = useState('');
    const [speaking, setSpeaking] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const messagesEndRef = useRef<HTMLLIElement>(null);
    const submitRef = useRef<HTMLImageElement>(null);
    const [tagRemoved, setTagRemoved] = useState<boolean>(false);
    const chatLisRef = useRef<HTMLUListElement>(null);
    const taliTopRef = useRef<HTMLLIElement>(null);
    const [helping, setHelping] = useState<boolean>(false);
    const [showMobileHelpScreen, setShowMobileHelpScreen] = useState(false);
    const [searchMethod, setSearchMethod] = useState('typed');
    const [showRegionSelector, setShowRegionSelector] = useState(false);
    const [regionSelected, setRegionSelected] = useState('');
    const [showSourceSelector, setShowSourceSelector] = useState(false);
    const [currentRegions, setCurrentRegions] = useState(global_regions);
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentRegions(global_regions);
    }, [global_regions])

    useEffect(()=>{    
        dispatch(reset());
        dispatch(addAnswer((<>Hi {window.localStorage.getItem("username")}!<br />{WELCOME}</>), 1000));
        dispatch(addAnswer('Here are some questions that I can answer.', 1100));
        dispatch(addTags(TAGS, 1200));
    }, [dispatch])

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView(false);
        }
    }, [messagesEndRef, chats]);
    
    const stripHtml = (html: any): string => {
       const tmp = document.createElement("DIV");
       tmp.innerHTML = html;
       return tmp.textContent || tmp.innerText || "";
    }

    const tagClickHandler = useCallback((tag: string, index?: number)=>{
        setSearchWord(tag)
        if (index) {
            dispatch(removeTags(index, 450))
            setTagRemoved(true)
        }
    }, [setSearchWord, setTagRemoved, dispatch])

    const sendMobileAnswers = useCallback((obj: any)=>{
        const reversedAnswers = [obj.data.reverse()[0]]
        switch(obj.type) {
            case 'lsi':
                reversedAnswers.forEach((d: any): void=>{
                    dispatch(addAnswer(<Lsi data={d} />))
                })
            break;
            case 'drs':
                const uid: string = Math.floor(Math.random() * 10000) +"-"+ Date.now()
                dispatch(addAnswer(<Map data={obj.data} uid={uid} />))
                reversedAnswers.forEach((d: any, idx: number): void=>{
                    dispatch(addAnswer(<DoctorInfo data={d} idx={idx} uid={uid}/>))
                })
            break;
            case 'drugInteraction':
                reversedAnswers.forEach((d: any): void=>{
                    dispatch(addAnswer(<DrugInteraction data={d} question={obj.question} />))
                })               
            break;
            case 'drugDosage':
                reversedAnswers.forEach((d: any): void=>{
                    dispatch(addAnswer(<DrugDosage data={d} />))
                })  
            break;
            case 'help':
                setShowMobileHelpScreen(true)
            break;
        }
    }, [dispatch])

    const submitHandler = useCallback(async(event)=>{
        event.preventDefault();
        if (loading || !searchWord) return;
        let sucMsg = 'That’s a great question. Here’s what I’ve found.';

        setHelping(false)
        dispatch(addQuestion(searchWord))

        if (searchWord.trim().toLowerCase() === "help") {
            setSearchWord('');
            if (isMobile) {
                setShowMobileHelpScreen(true);
            } else if (fullpage) {
                onFullChange();
                dispatch(editAnswer(sucMsg, {type: 'help', tagClickHandler}))
            } else {
                dispatch(editAnswer(sucMsg, {type: 'help', tagClickHandler}))
            } 
            setHelping(true)
            return    
        }

        if (taliTopRef.current && taliTopRef.current!.style.display !== "none") {
            taliTopRef.current.style.animation = 'fadeOut 0.5s';
            setTimeout(()=>{
                if (taliTopRef.current) taliTopRef.current.remove()
            }, 500)
        }

        dispatch(addAnswer(<i className="fa fa-spin fa-spinner fa-2x"></i>))
        setLoading(true)
        try {
            const updated_regions = Object.keys(global_regions).filter((key) => global_regions[key] === true)
            const {data} = await request.getAnswers(searchWord, searchMethod, updated_regions, regionKey);
            if (!data.done) throw new Error(data.data);
            setLoading(false)
            setSearchMethod('typed')

            const obj: any = {type: 'lsi', data: data.answers, question: searchWord, center: null, qid: data.qid, canReportCorrectAnswer: data.can_report_correct_answer, inCorpus: data.in_corpus};

            if (data.type && data.type==="drs") {
                if (data.data.length === 0) {
                    setSearchWord('');
                    setLoading(false);
                    dispatch(editAnswer("No result found."))
                    return;
                } else {
                    obj.type = "drs";
                    obj.data = data.data;
                    obj.center = data.center;
                    if (data.zipcode) {
                        sucMsg = `I've found ${obj.data.length} physicians at ${data.zipcode}.`;
                    }
                }
            } else if (data.type && (data.type==="drugInteraction" || data.type==="drugDosage")) {
                if (data.data.length === 0) {
                    setSearchWord('');
                    setLoading(false);
                    dispatch(editAnswer("No result found."))
                    return;
                } else {
                    obj.type = data.type;
                    obj.data = data.data;
                    obj.question = searchWord;
                }
            } else {
                if (obj.data.length === 0) {
                    setSearchWord('');
                    setLoading(false);
                    dispatch(editAnswer("Sorry, we weren’t able to find any results related to your question. Please rephrase and try again!"))
                    return;    
                }

                obj.data.map((p: any)=>{
                    const ptext = stripHtml(p.text).replace(/[\n\r]/g, '') as string
                    let speak: any = ptext?.match(/\[b\](.+?)\[\/b\]/g);
                    if (Array.isArray(speak) && speak.length > 0) {
                        speak = speak[0]
                        speak = speak.substring(3)
                        speak = speak.substring(0, speak.length - 4)
                        p.speak = speak;    
                    }
                    if (p.type==="faq") {
                        p.text = p.text.replace('[b]', '<b>').replace('[/b]', '</b>')    
                    } else {
                        p.text = stripHtml(p.text).replace('[b]', '<b>').replace('[/b]', '</b>')    
                    }
                    if (p.text.indexOf('[/src]') > -1) {
                        p.text = p.text.replace(/\[src="/g, `<a target='_blank' href="`).replace(/\[\/src\]/g, '</a>').replace(/"\]/g, '">')
                    }
                    p.text = p.text.replace(/●/g, '*').replace(/&#x25CF;/g,'*')
                    
                    // Only show bolded text
                    if (p.text.includes('<b>') && p.text.match(/<b>(.*?)<\/b>/gmui)) {
                        p.text = p.text.match(/<b>(.*?)<\/b>/gmui)[0];
                    }
                    
                    return p;
                })
                if (REGIONAL_ENABLED) {
                    obj.regional = regionKey;
                }
            }

            dispatch(editAnswer(sucMsg, obj))
            setSearchWord('');

            if (isMobile) {
                sendMobileAnswers(obj);
            }

            if (searchMethod==='readloud' && obj.type === "lsi" && 'speechSynthesis' in window) {
                const speakText = obj.data[0].speak;
                if (!!speakText) {
                    window.speechSynthesis.speak(new SpeechSynthesisUtterance(speakText));
                }
            }

            if (fullpage) {                
                setTimeout(()=>{
                    onFullChange(false);
                }, 800)
            }

        } catch(e) {
            setLoading(false);
            if (e.response && e.response.data.relogin) {
                OnLogOut();
            } else {
                dispatch(editAnswer(e.message))
            }
        }
    }, [OnLogOut, searchMethod, setSearchMethod, searchWord, setSearchWord, loading, dispatch, setLoading, taliTopRef, onFullChange, fullpage, sendMobileAnswers, isMobile, tagClickHandler, global_regions, regionKey]);

    const inputChangeHandler = useCallback(event=>{
        setSearchWord(event.target.value);
        if (event.target.value === '') {
            setSearchMethod('typed')
            if (tagRemoved) {
                dispatch(addTags(TAGS, 300));
                setTagRemoved(false)
            }
        }
    }, [setSearchWord, dispatch, setTagRemoved, tagRemoved, setSearchMethod])

    const voiceHandler = useCallback((status: boolean)=>{

        if (!recognition) {
            setSpeaking(false)
            new Noty({
                type: 'warning',
                text: 'Your browser does not support speech recognition',
                timeout: 3000,
            }).show();
            return;
        }

        if (status === false) {
            recognition.stop()
            setSpeaking(false)
            return;
        }

        setSpeaking(true)
        recognition.start()

        recognition.onresult = (event: any): void => {
            const current = event.resultIndex;
            const transcript = event.results[current][0].transcript;

            if (transcript !== "") {
                recognition.stop()
                setSpeaking(false)
                setSearchWord(transcript+"?");
                setSearchMethod('readloud')
                setTimeout(()=>{
                    if (submitRef.current) {
                        submitRef.current.click();
                    }    
                }, 200)
            }
        }
                
        recognition.onerror = (event: any): void => {
            recognition.stop()
            setSpeaking(false)
            
            if(event.error === 'no-speech') {
                new Noty({
                    type: 'warning',
                    text: 'No speech was detected. Try again',
                    timeout: 3000,
                }).show();
            } else if (event.error === 'not-allowed') {
                new Noty({
                    type: 'warning',
                    text: 'Access to microphone is blocked',
                    timeout: 3000,
                }).show();
            } else {
                new Noty({
                    type: 'warning',
                    text: 'Error: '+ event.error,
                    timeout: 3000,
                }).show();
            }
        }  

    }, [setSpeaking, submitRef, setSearchMethod])

    const helpIconHandler = useCallback(()=>{
        const sucMsg = 'That’s a great question. Here’s what I’ve found.';
        dispatch(addQuestion('Help')); 
        dispatch(editAnswer(sucMsg, {type: 'help', tagClickHandler}));
        setHelping(true)
    }, [dispatch, setHelping, tagClickHandler])

    const setSelector = useCallback((selector) => {
        setShowRegionSelector(selector === 'regional');
        setShowSourceSelector(selector !== 'regional');
    }, []);

    const updateGlobalRegions = (key: string, event: any) => {
        let updated_regions = Object.assign({}, currentRegions);
        updated_regions[key] = event.target.checked;
        setCurrentRegions(updated_regions);
    }

    const submitGlobalRegions = async () => {
        dispatch(setRegions(currentRegions));
        await request.updateUserProfile({'global_regions': currentRegions});
        setShowSourceSelector(false);
    }

    const updateRegion = useCallback((key)=>{
        setShowRegionSelector(false);
        dispatch(updateRegionKey(key));
    }, [dispatch]);

    const changeToHelp = useCallback(() => {
        setShowRegionSelector(false);
        setShowSourceSelector(false);
        setShowMobileHelpScreen(true);
    }, []);

    useEffect(()=>{
        if (!isMobile) {
            setShowRegionSelector(false);
            setShowSourceSelector(false);
        }
    }, [isMobile])

    let helpingClass = 'helpIcon '
    if (helping) {
        helpingClass += 'helping '
    }

    const chatStyle = {
        width: fullpage ? '100%' : '30%',
        float: fullpage ? 'none' : 'left',
        height: isMobile && showSourceSelector ? '100%' : '80vh',
    } as React.CSSProperties;

    const formStyle = {
        position: !isMobile ? fullpage ? 'fixed' : 'absolute' : 'fixed',
        bottom: '0',
        width: isMobile || !fullpage ? '96%' : null
    } as React.CSSProperties;

    const chatLisDivStyle = {
        width: isMobile ? '100%' : fullpage ? '470px' : null,
        transition: 'transform 1s',
        padding: isMobile ? '10px' : null,
    } as React.CSSProperties;

    const chatUlStyle = {
        overflowY: 'auto',
        maxHeight: isMobile? window.innerHeight - 200 + 'px' : '76vh',
    } as React.CSSProperties;

    const chatFieldStyle = {
        width: isMobile? '100%' : fullpage ? '533px' : '100%'
    } as React.CSSProperties;

    const chatLis = chats.map((chat: any, index: number)=>{
        switch(chat.bubble) {
            case Bubble.ANSWER:
                let image = false;
                // @ts-ignore: Unreachable code error
                if (!chats[index+1] || (chats[index+1] && (chats[index+1]) as {content: string; bubble: Bubble}).bubble !== Bubble.ANSWER) {
                    image = true;
                }
                if (isMobile) image = false;
                return (<li key={index}><AnswerBubble fullpage={fullpage} isMobile={isMobile} image={image} data={chat.content}>{chat.content}</AnswerBubble></li>)
            case Bubble.QUESTION:
                return (<li key={index}><QuestionBubble isMobile={isMobile} content={chat.content} fullpage={fullpage} /></li>)
            case Bubble.TAGS:
                let className = '';
                if (chat.hide) {
                    className = "hideMeNow"
                }
                return (<li key={index}><TagsBubble addClass={className} content={chat.content} fullpage={fullpage} clickHandler={(tag:string)=>tagClickHandler(tag, index)} /></li>)
        }
        return null;
    })

    let submitBtn = null
    let placeholder = "Type any question here"
    let chatFieldClass = "chatField";
    if (speaking) {
        placeholder = "Speak Now"
        chatFieldClass = "chatField speaking"
        submitBtn = (<img src={Cancel} className='sendBtn' onClick={()=>voiceHandler(false)} alt="stop" />)
    } else {
        if (searchWord) {
            chatFieldClass = "chatField filled";
            submitBtn = (<img src={Send} className='sendBtn' onClick={submitHandler} alt="send" ref={submitRef} />)
        } else {
            submitBtn = (<img src={Speak} className='sendBtn' onClick={()=>voiceHandler(true)} alt="speak" />)
        }
    }

    let mainclass = "chatArea ";
    if (fullpage) mainclass += "fullpage ";
    if (isMobile) mainclass += "ismobile ";

    return (
        <div style={chatStyle} className={mainclass}>
            <div className={fullpage ? undefined : 'stickMe'}>
                {(fullpage || isMobile) &&
                    <div style={{paddingBottom: '20px', paddingTop: '20px'}}>
                        {fullpage && (<img src={Logo} className="chatLogo" alt="logo" />)}
                        {isMobile && (
                            <ul className="mobile-menu">
                                <li>
                                    <Dropdown alignRight={true} className="mobileBtn">
                                        <Dropdown.Toggle id="dropdown-basic">{window.localStorage.getItem("username")}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={changeToHelp}>Help</Dropdown.Item>
                                            {SOURCES_ENABLED && <Dropdown.Item onClick={()=>setSelector('sources')}>Sources</Dropdown.Item>}
                                            <Dropdown.Item onClick={OnLogOut}>Logout</Dropdown.Item>
                                            {REGIONAL_ENABLED && <Dropdown.Item onClick={()=>setSelector('regional')}>Change Region: {regions[regionKey] || 'World'}</Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                {REGIONAL_ENABLED && 
                                    <li>
                                        <button className="btn btn-region" onClick={event=>{setShowSourceSelector(false);setShowRegionSelector(old=>!old)}}>
                                            <i className="fa fa-map-marker" aria-hidden="true"></i> {regionKey === 'World' ? 'Global' : regionKey}
                                        </button>
                                    </li>
                                }
                            </ul>
                        )}
                    </div>
                }
                {(!showRegionSelector && !showSourceSelector && !showMobileHelpScreen) && <>
                    <div style={chatLisDivStyle} className="chatLisDiv">
                        {!fullpage && !isMobile && <div><div className={helpingClass} onClick={helpIconHandler}></div><div className="clearfix"/></div>}
                        <ul className="chatLis" ref={chatLisRef} style={chatUlStyle}>
                            <li className="tali-top" ref={taliTopRef}>
                                <img src={PhotoBig} className="tali-photo-big"  alt="tali big"/>
                                <p className="tali-intro" data-testid="tali-intro">
                                    Hi! I’m <span className='colorStyle'>Tali</span>{!isMobile && (<>,  {INTRO}</>)}
                                </p>
                                {isMobile && <p className="tali-help">Type “Help” to see the list of questions you can ask me </p>}
                            </li>  
                            {chatLis}
                            <li ref={messagesEndRef}></li>
                        </ul>
                    </div>
                    <div className="d-flex justify-content-center">
                        <form onSubmit={submitHandler} style={formStyle}>
                            <div className="d-flex flex-row justify-content-center">
                                <input type="text" disabled={loading} style={chatFieldStyle} className={chatFieldClass} placeholder={placeholder} value={searchWord} onChange={event=>inputChangeHandler(event)}/>
                                {submitBtn}
                            </div>
                        </form>
                    </div>
                </>
                }
                {(showRegionSelector && isMobile) && <div className="regionContainer">
                    <h3 className="dropdownHeader">SELECT A REGION <span className="float-right font-weight-normal" onClick={event=>{setRegionSelected('');setShowRegionSelector(false);}}>CLOSE</span></h3>
                    <ul className="dropdownList">
                        {Object.keys(regions).map((key, i) => {
                            let liClass = "d-flex align-items-center regionListItem ";
                            if (regionSelected === key) {
                                liClass += "active "
                            }
                            return (
                                <li key={i.toString()} className={liClass} onClick={()=>setRegionSelected(key)}>
                                    <p className="regionText">{regions[key]}</p>
                                </li>
                            )
                        })}
                        <li className={'d-flex align-items-center regionListItem '+(regionSelected==="World"?'active ':'')} onClick={()=>setRegionSelected('World')}>
                            <p className="regionText">Global</p>
                        </li>
                    </ul>
                    <button className="sourceSubmitBtn" onClick={event=>updateRegion(regionSelected)}>Select Region</button>
                </div>}
                {(showSourceSelector && isMobile) && <div className="sourceContainer">
                    <h3 className="dropdownHeader">SOURCES <span className="float-right font-weight-normal" onClick={event=>setShowSourceSelector(false)}>CLOSE</span></h3>
                    <ul className="dropdownList">
                        {Object.keys(globalSources).map((key, i) => (
                            <li key={i.toString()} className="d-flex align-items-center justify-content-between sourceListItem">
                                <div className="d-flex align-items-center source-info">
                                    <img src={globalSources[key].logo} alt={globalSources[key].name} className="source-logo"/>
                                    <p className="regionText">{globalSources[key].name}</p>
                                </div>
                                <Form.Check 
                                    type="switch"
                                    id={key}
                                    label=""
                                    checked={currentRegions[key]}
                                    onChange={(e: any) => updateGlobalRegions(key, e)}
                                />
                            </li>
                        ))}
                    </ul>
                    <button className="sourceSubmitBtn" onClick={submitGlobalRegions}>SAVE AND CLOSE</button>
                </div>}
                {(showMobileHelpScreen && isMobile) && <HelpPage tagClickHandler={tagClickHandler} onClose={()=>setShowMobileHelpScreen(false)} />}
            </div>
        </div>
    );
}

export default Chat;
