import {SET_REGIONS} from './actions';

type Action = {
    type: string;
    global_regions: State;
}

type State = {
    CDC: boolean;
    ECDC: boolean;
    WHO: boolean;
    PHAC: boolean;
    CDPH: boolean;
    FDA: boolean;
    JHU: boolean;
    UTD: boolean;
    Merck: boolean;
}

const default_regions = {
    CDC: true,
    ECDC: true,
    WHO: true,
    PHAC: true,
    CDPH: true,
    FDA: true,
    JHU: true,
    UTD: true,
    Merck: true
}

export default function global_regions(state: State = default_regions, action: Action): any {
    switch (action.type) {
        case SET_REGIONS:
            return action.global_regions;
        default:
            return state;
    }
}
