/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {SET_AUTH} from './actions';

type Action = {
    type: string;
    username: string | null;
    token: string | null;
    expire: string | null;
}

type State = {
    [index: string]: string | null; 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function auth(state: State = {
    username: window.localStorage.getItem("username")!,
    token: null
}, action: Action): any {
    switch (action.type) {
        case SET_AUTH:
            const newState = {
                username: action.username,
                token: action.token,
                expire: action.expire,
            };
            return newState;
        default:
            return state;
    }
}
