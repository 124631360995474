/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import { load } from 'recaptcha-v3'

import requests from '../../utils/requests'
import {SITE_KEY} from '../../utils/constants';

import Logo from '../../images/svg/Logo-light.svg';
import './ForgotPassword.css';

const ForgotPassword: React.FC = () => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState<any>('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const history = useHistory();

    const changeStep = useCallback((stepName)=>{
        setError('');
        setStep(stepName);
        switch(stepName) {
            case 'findaccount':
                setTitle('First, let’s find your account')
            break;
            case 'entercode':
                setTitle('We’ve sent a verification code to your email')
            break;
            case 'newpassword':
                setTitle('Choose a new password');
            break;
            case 'done':
                setTitle('Your password has been changed successfully')
            break;
        }
    }, [])

    useEffect(()=>{
        if (username) {
            history.replace('/')
        } else {
            changeStep('findaccount')
        }
    }, [username, history, changeStep])

    const findAccount = useCallback(async ()=>{
        setError('')
        if (!email || email==="") {
            setError("Please enter your email")
            return;
        }
        setLoading(true);
        try {
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('forgetpassword')   
            const {data} = await requests.findAccount(email, token);
            if (!data.done) throw new Error(data.data);
            setLoading(false);
            changeStep('entercode');
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
        }

    }, [email, changeStep])

    const verifyCode = useCallback(async ()=>{
        setError('')
        if (!email || email==="") {
            setError("Please enter your email")
            return;
        }
        if (!code || code==="") {
            setError("Please enter verification code");
            return;
        }
        setLoading(true);
        try {
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('forgetpassword')   
            const {data} = await requests.verifyCode(email, code, token);
            if (!data.done) throw new Error(data.data);
            setLoading(false);
            changeStep('newpassword')
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
        }

    }, [email, code, changeStep])

    const saveNewPassword = useCallback(async ()=>{
        setError('')
        if (!email || email==="") {
            setError("Please enter your email")
            return;
        }
        if (!code || code==="") {
            setError("Please enter verification code");
            return;
        }
        if (newPassword.length < 8) {
            setError("Password must include at least 8 characters");
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setError("Please check passwords, they are not same");
            return;
        }
        setLoading(true);
        try {
            const {data} = await requests.saveNewPassword(email, code, newPassword);
            if (!data.done) throw new Error(data.data);
            setLoading(false);
            changeStep('done')
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
        }

    }, [email, code, newPassword, confirmNewPassword,changeStep])


    return (
        <div className="loginpage">
            <header className="login-header d-flex flex-row justify-content-center align-items-center">
                <Link to="/"><img src={Logo} className="Logo" alt="Logo" /></Link>
            </header>
            {error && 
                <div className="fp-alert">
                    <div className="row">
                        <div className="col-lg-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </div>
                        <div className="col-lg-11">
                            {error}
                        </div>
                    </div>
                </div>
            }
            <div className="login-form">
                <h1 className="loginform-title d-flex flex-row justify-content-center align-items-center p-4 text-center">{title}</h1>
                <hr className="login-divider" />
                <div className="container fp-container">
                    {step==='findaccount' &&
                        <div className="row fp-form">
                            <div className="col-lg-12">
                                <input type="email" disabled={loading} placeholder="Please enter your email" className="fp-email" value={email} onChange={event=>setEmail(event.target.value)}/>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <button onClick={event=>history.replace('/')}>Cancel</button>
                            </div>
                            <div className="col-lg-6">
                                <button className="active" onClick={event=>findAccount()} disabled={loading}>{loading ? <i className="fa fa-spin fa-spinner"></i> : 'Find Account'}</button>
                            </div>
                        </div>
                    }
                    {step==='entercode' && 
                        <div className="row fp-form">
                            <div className="col-lg-12">
                                <input type="number" disabled={loading} placeholder="Enter code" className="fp-email" value={code} onChange={event=>setCode(parseInt(event.target.value))}/>
                            </div>
                            <div className="col-lg-12">
                                <button className="active" onClick={event=>verifyCode()} disabled={loading}>{loading ? <i className="fa fa-spin fa-spinner"></i> : 'Submit'}</button>
                                <p className="reg-text"><a href="/" onClick={event=>{event.preventDefault();changeStep('findaccount')}}>Didn’t receive code? Resend</a></p>
                            </div>
                        </div>
                    }
                    {step==='newpassword' &&
                        <div className="row fp-form">
                            <div className="col-lg-12">
                                <p className="fp-password-intro">Password must include at least 8 characters including at least 1 number or 1 special character.</p>
                            </div>
                            <div className="col-lg-12">
                                <input type="password" disabled={loading} placeholder="New password" className="fp-email mt-0" value={newPassword} onChange={event=>setNewPassword(event.target.value)}/>
                            </div>
                            <div className="col-lg-12">
                                <input type="password" disabled={loading} placeholder="Retype new password" className="fp-email mt-0" value={confirmNewPassword} onChange={event=>setConfirmNewPassword(event.target.value)}/>
                            </div>
                            <div className="col-lg-12">
                                <button className="active" onClick={event=>saveNewPassword()} disabled={loading}>{loading ? <i className="fa fa-spin fa-spinner"></i> : 'Submit'}</button>
                            </div>
                        </div>
                    }
                    {step==='done' && 
                        <div className="row fp-form mt-4">
                            <div className="col-lg-12">
                                <button className="active" onClick={event=>{history.push('/')}} disabled={loading}>{loading ? <i className="fa fa-spin fa-spinner"></i> : 'Done'}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
