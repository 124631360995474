import React from 'react';
import {createStore, applyMiddleware } from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Notfound from './pages/Notfound';
import Main from './pages/Main';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ResendEmail from './pages/ResendEmail/ResendEmail';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ChooseResource from './pages/ChooseResource/ChooseResource';
import Region from './pages/Region/Region';

import reducers from './reducers';

import 'bootstrap/dist/css/bootstrap.min.css';
import "noty/lib/noty.css";  
import "noty/lib/themes/mint.css";
import 'leaflet/dist/leaflet.css'

import './App.css';

const store = createStore(reducers, applyMiddleware(thunk))

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/resend/:vemail?" exact component={ResendEmail} />
          <Route path="/confirm-email/:vcode?" exact component={VerifyEmail} />
          <Route path="/forgotpassword" exact component={ForgotPassword} />
          <Route path="/choose/resource" exact component={ChooseResource} />
          <Route path="/resources" exact><Main fullPage={false} page="resources" /></Route>
          <Route path="/region" exact component={Region} />
          <Route component={Notfound} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
