/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback, useRef} from 'react';

import Noty from 'noty';  

import {GOOGLE_KEY, getGender} from '../../utils/constants';

import Close from '../../images/svg/Close.svg';
import './Doctors.css';

declare let google: any;

type DoctorsProps = {
    data: any;
}

type DrInfo = {
    first_name: string;
    last_name: string;
    former_name: string;
    gender: string;
    education: string;
    spoken_languages: string[];
    specializations: string[];
    hospitals: any[];
    street: string;
    phone: string;
    fax: string;
    lat: number;
    lng: number;
}

const Doctors: React.FC<DoctorsProps> = ({data}) => {    
    const [map, setMap] = useState<any>({});
    const [markers, setMarkers] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const [drinfo, setDrinfo] = useState<DrInfo>();
    const innerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const [itemActive, setItemActive] = useState(0)
    const [activeBox, setActiveBox] = useState<number | null>(null);

    const resetCenter = useCallback(()=>{
        if (!map.fitBounds) return;
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i]);
        }
        map.fitBounds(bounds);
        setActiveBox(null)
    }, [map, markers, setActiveBox])

    useEffect(()=>{
        if (document.getElementById('gscript')) {
            setActiveBox(null)

            if (mapRef.current) {
                setMap(new google.maps.Map(mapRef.current, {
                    zoom: 12, 
                    center: new google.maps.LatLng(+data.center['lat'], +data.center['lng']),
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false              
                }));       
            }

            return;
        }
        
        const googleScript = document.createElement("script");
        googleScript.id = "gscript"
        googleScript.async = true;
        googleScript.defer = true;
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}`;
        googleScript.onload = (): void => {
            setMap(new google.maps.Map(mapRef.current, {
                zoom: 12, 
                center: new google.maps.LatLng(+data.center['lat'], +data.center['lng']),
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false              
            }));   
        };
        window.document.body.appendChild(googleScript)
    }, [data, mapRef])

    useEffect(()=>{
        if (map.setCenter && map.fitBounds) {
            const mks: any[] = [];
    
            data.data.forEach((dr: any, idx: number)=>{
                const point = new google.maps.LatLng(+dr.lat, +dr.lng);
                mks.push(point);
                const marker = new google.maps.Marker({
                    map: map,
                    position: point,
                    label: { color: '#ffffff', fontWeight: 'bold', fontSize: '14px', text: (idx+1).toString() },
                    icon: activeBox === idx ? '/marker2.png' : '/marker.png'
                });
                marker.addListener('click', ()=>{
                    setActiveBox(idx)
                    map.setZoom(18)
                    map.setCenter(point)
                    marker.setIcon('/marker2.png')

                    if (document.querySelector('div.drBox.highLight')){
                        document.querySelector('div.drBox.highLight')!.scrollIntoView(false);
                    }
                });  
            });  
    
            const bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < mks.length; i++) {
                bounds.extend(mks[i]);
            }
            map.fitBounds(bounds);

            setMarkers(mks)
        }
    }, [map, data, setMarkers, activeBox, setActiveBox])

    const copyToClipBoard = useCallback((text)=>{
        // SOURCE: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        const input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
    
        new Noty({
            type: 'info',
            text: 'Copied the number: '+ text,
            timeout: 3000,
        }).show();

    }, [])

    const getDoctorInfo = (dr: DrInfo): any => {
        const info = [];
        if (dr.specializations.length>0) info.push(dr.specializations.join(", "));
        info.push(dr.street)
        if (dr.fax) {
            let f: string | string[] | RegExpMatchArray | null = dr.fax.split(":")
            f = f[f.length - 1].trim()
            f = f.match(/\d+/g);
            if (f) {
                const faxNumber = f.map(Number).join('');
                const fax = (<span onClick={()=>copyToClipBoard(faxNumber)}>F: {f} <span style={{fontSize: '10px'}}><i className="far fa-copy"></i> COPY</span></span>)
                info.push(fax)
            }
        }
        return info.map((inf, idx)=>{
            return (<span key={idx}>{inf}<br /></span>)
        })
    }

    const hideModal = useCallback(()=>{
        if (!show || !innerRef.current) return;
        innerRef.current.classList.add("hideInfoModal");
        setTimeout(()=>{
            setShow(false);
        }, 290)
    }, [show, innerRef, setShow])

    const showModal = useCallback((dr: DrInfo)=>{
        setDrinfo(dr);
        setShow(true);
    }, [])

    const drBoxNumberHandler = useCallback((idx, dr)=>{
        if (!map.setCenter) return;
        setActiveBox(idx)
        map.setZoom(18)
        map.setCenter(new google.maps.LatLng(+dr.lat, +dr.lng))
    }, [map])


    const getDrBoxClass = useCallback((idx: number): string=>{
        let cl = "drBox";
        if (idx === activeBox) {
            cl += " highLight"
        }
        return cl;
    }, [activeBox])

    return (
        <>  
            <div className="drsIntroDiv">
                <h6 className="youAsked">YOU ASKED</h6>
                <h3 className="lsisQuestion" style={{cursor: 'pointer'}} onClick={resetCenter}>{data.question} <i className="fas fa-map-marked-alt"></i></h3>
            </div>
            <div className="row" style={{margin: 0, padding: 0}}>
                <div className="col-lg-4 drsDiv">
                    <ul className="drsBoxs">
                        {data.data.map((dr: any, idx: number)=>{
                            return (
                                <li className={getDrBoxClass(idx)} key={idx}>
                                    <div className="drBoxNumber" onClick={(): void=>drBoxNumberHandler(idx, dr)}>{idx + 1}</div>
                                    <div className="drBox1" onClick={(): void=>drBoxNumberHandler(idx, dr)}>
                                        <h1 className="drName">{dr.first_name} {dr.last_name}</h1>
                                        <p className="drStreet">{getDoctorInfo(dr)}</p>
                                    </div>
                                    <hr style={{margin: 0}} />
                                    <div className="text-center">
                                        <button className="btn btn-link moreInfoBtn" data-testid={"drBox1-"+idx} onClick={()=>showModal(dr)}><i className="fas fa-plus-circle"></i> MORE INFO</button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="col-lg-8">
                    <div id="map" className="mapBox" ref={mapRef}></div>
                </div>
            </div>
            {show && drinfo && 
            <div className="modalBackDrop">
                <div className="answerModal doctorInfoModal" ref={innerRef}>
                    <div className="modalContent">
                        <h1 className="drModalTitle" data-testid="drModalTitle">{drinfo.first_name} {drinfo.last_name}</h1>
                        <ul className="drModalTabs">
                            <li onClick={()=>setItemActive(0)} className={itemActive===0 ? 'active': undefined}>Summary</li>
                            <li onClick={()=>setItemActive(1)} className={itemActive===1 ? 'active': undefined}>Practice information</li>
                            <li onClick={()=>setItemActive(2)} className={itemActive===2 ? 'active': undefined}>Hospital privileges</li>
                            <li onClick={()=>setItemActive(3)} className={itemActive===3 ? 'active': undefined}>Specialties</li>
                        </ul>
                        <div className="drModalDetail">
                            {itemActive===0 && <div>
                                {drinfo.former_name && drinfo.former_name !== "False" &&
                                    <><b>Former Name:</b> {drinfo.former_name}<br /></>
                                }
                                <b>Gender:</b> {getGender(drinfo.gender)}<br />
                                <b>Languages Spoken:</b> {drinfo.spoken_languages.join(', ')}<br />
                                <b>Education:</b> {drinfo.education}
                            </div>}
                            {itemActive===1 && <div>
                                {drinfo.street}<br />
                                {drinfo.phone}<br />
                                {drinfo.fax}<br />
                            </div>}
                            {itemActive===2 && <div>
                                {drinfo.hospitals && drinfo.hospitals.length>0 &&
                                    <table className="table table-borderless">
                                        <thead>
                                            <th>Hospital</th>
                                            <th>Location</th>
                                        </thead>
                                        <tbody>
                                            {drinfo.hospitals.map((h, idx)=>{
                                                return (<tr key={idx}>
                                                    <td>{h.name}</td>
                                                    <td>{h.location}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>}
                            {itemActive===3 && <div>
                                {drinfo.specializations.map((s,idx)=>{
                                    return (<span key={idx}>{s}<br /></span>)
                                })}
                            </div>}
                        </div>
                    </div>
                    <div className="text-center closeBtn" onClick={hideModal}><img src={Close} alt="close" /><p className="feedBackBoxTag">Close</p></div>
                </div>
            </div>
            }
        </>
    );
}

export default Doctors;