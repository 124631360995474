let endurl = '/api';
let searchurl = '/search';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    endurl = 'http://127.0.0.1:8000/api'
    searchurl = 'http://127.0.0.1:8000/search'
}
export const ENDPOINT_URL = endurl;
export const SEARCH_ENGINE_URL = searchurl;

export const SITE_KEY = '6LcgiM8UAAAAAAr1SSf2gULniWqbkDiU48hhVrtg';
export const GOOGLE_KEY = 'AIzaSyD5GxFtCcycgBdeg1ylKXFnC8rKJItHEOk';

export const INTRO = 'Ask me any medical question';
export const WELCOME = 'I can answer any medical questions';

export const REGIONAL_ENABLED = process.env.REACT_APP_REGIONAL_ENABLED === 'true' ? true : false;
export const SOURCES_ENABLED = process.env.SOURCES_ENABLED === 'true' ? true : false;

export const TAGS = [
    'What is the recommended treatment for a septic abortion?',
    'What are some of the withdrawal symptoms of marijuana?',
    'Does abacavir interact with leflunomide?',
    'What is the recommended dosage of chloroquine?',
]

export const HelpItems: any[] = [
    {
        tag: 'CIG',
        desc: 'Key immunization information'
    },
    {
        tag: 'CIG',
        desc: 'Vaccine safety'
    },
    {
        tag: 'CIG',
        desc: 'Vaccination of specific populations'
    },
    {
        tag: 'CIG',
        desc: 'Active vaccines'
    },
    {
        tag: 'CIG',
        desc: 'Passive immunization'
    },
    {
        tag: 'DRUGS.COM',
        desc: 'Drug Interactions'
    },
]

export const getGender = (g: string): string => {
    if (g==="M") {
        return 'Male'
    } else if(g==='F') {
        return 'Female'
    } else {
        return 'Unsure'
    }
}

export const getCategory = (c: string): string => {
    if (c==="MA") {
        return "Major";
    } else if (c==="MO") {
        return "Moderate";
    } else if (c==="MI") {
        return "Minor"
    } else {
        return "Not Set"
    }
}

export const getBackgroundColor = (c: string): string => {
    if (c==="MA") {
        return "#E50123";
    } else if (c==="MO") {
        return "#f57c00";
    } else if (c==="MI") {
        return "#fbc02d";
    } else {
        return "#ffffff"
    }
}