import {SET_ACTIVE_MARKER} from './chatActions';

type Action = {
    type: string;
    uid: string;
    idx: number;
}

export default (state: any = {}, action: Action): any => {
    switch (action.type) {
        case SET_ACTIVE_MARKER:
            const cpstate = {...state}
            cpstate[action.uid] = action.idx;
            return cpstate;
        default:
            return state;
    }
}
  