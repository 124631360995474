export interface Answer {
    id?: string;
    text: string;
    'taken-from': string;
    url: string;
    hide?: boolean;
};

export enum Bubble {
    QUESTION,
    ANSWER,
    TAGS
}