/* eslint-disable react/prop-types */
import React, {useState, useCallback} from 'react';
import { useHistory } from "react-router-dom";
import {useDispatch} from 'react-redux';

import {regions} from '../../utils/helpers';
import {updateRegionKey} from '../../reducers/actions'

import Logo from '../../images/svg/Logo-dark.svg';
import PhotoBig from '../../images/photo-big.png';

import './Region.css';

const Region: React.FC = () => {
    const [region, setRegion] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch()

    const saveRegion = useCallback((r?: string)=>{
        let selectedRegion = region;
        if (r) {
            selectedRegion = r;
        }
        setLoading(true)
        dispatch(updateRegionKey(selectedRegion, history))
    }, [setLoading, region, dispatch, history])

    return (
       <div className="regionPage">
            <img src={Logo} className="regionPageLogo" alt="logo" />

            <div className="d-flex flex-column justify-content-center align-items-center regionBox">
                <img src={PhotoBig} className="tali-photo-big"  alt="tali big"/>
                <p className="tali-intro" data-testid="tali-intro">
                    Hi! I’m <span className='colorStyle'>Tali</span>,  choose your region to begin
                </p>
                {!loading &&
                    <>
                        <select className="regionSelectInput" value={region} onChange={event=>setRegion(event.target.value)}>
                            <option disabled value="">Choose Region</option>
                            {Object.keys(regions).map((key, idx) =>{
                                return (<option value={key} key={idx}>{
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                    // @ts-ignore: Unreachable code error
                                    regions[key]
                                }</option>)
                            })}
                        </select>
                        <p className="regionPageText">We will prioritize your search results based on your region, otherwise we will send you global results.</p>
                        <button className="saveRegionBtn" disabled={region===''} onClick={event=>saveRegion()}>Save Region</button>
                        <p className="regionSkip" onClick={event=>saveRegion('World')}>Show me global results</p>
                    </>
                }
                {loading &&
                    <p className="text-center"><i className="fa fa-spin fa-spinner fa-2x"></i></p>
                }
            </div>
       </div>
    );
}

export default Region;
