import {SET_RESULT} from './chatActions';

type Action = {
    type: string;
    data?: any[];
    question: any;
}

type State = {
    data?: any[],
    question?: string;
}

export default function result(state: State = {}, action: Action): any {
    switch (action.type) {
        case SET_RESULT:
            return action.data;
        default:
            return state;
    }
}
  