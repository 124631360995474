/* eslint-disable react/prop-types */
import React, {useState, useCallback} from 'react';
import {Modal} from 'react-bootstrap';

type LsiType = {
    data: any;
}

const Lsi: React.FC<LsiType> = ({data}) => {
    const [lgShow, setLgShow] = useState<boolean>(false)

    const openSource = useCallback(()=>{
        window.open(data.url, "_blank");
    }, [data])


    return (
        <>
            <p dangerouslySetInnerHTML={{ __html: data.text}} className="lsiText"></p>
            <button className="readMore" data-testid="readMore" onClick={()=>{
                if (data.type==='faq') {
                    window.open(data.url, "_blank"); 
                } else {
                    setLgShow(true)
                }
            }}>READ MORE &gt;</button>
            <Modal
                size="lg"
                show={lgShow}
                onHide={(): void => setLgShow(false)}
            >
                <Modal.Header closeButton>
                    <p className="lsiTaken">{data['taken-from']}</p>
                </Modal.Header>
                <Modal.Body >
                    <p data-testid="dataText" className="lsiText" dangerouslySetInnerHTML={{ __html: data.text}}></p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={openSource} className="modalSourceButton">Go to source</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Lsi;
