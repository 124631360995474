/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {Bubble} from '../utils/types';
import {ADD_ANSWER, EDIT_ANSWER, ADD_QUESTION, ADD_TAGS, HIDE_TAGS, REMOVE_TAGS, RESET} from './chatActions';

type Action = {
    type: string;
    content?: any;
    hide?: boolean;
    data?: any[];
}

type State = any[]

export default function chats(state: State = [], action: Action): any {
    switch (action.type) {
        case RESET:
            return [];
        case ADD_ANSWER:
            const answer = {
                bubble: Bubble.ANSWER,
                content: action.content,
            };
            return [...state, answer];
        case EDIT_ANSWER:
            const cpstate = [...state]
            cpstate.pop();
            if (!action.data) {
                cpstate.push( {
                    bubble: Bubble.ANSWER,
                    content: action.content,
                    data: action.data || false
                })
            }
            return cpstate;
        case ADD_QUESTION:
            const question = {
                bubble: Bubble.QUESTION,
                content: action.content,
            };
            return [...state, question];
        case ADD_TAGS:
            const cpState = [...state]
            const last = cpState[cpState.length - 1]
            if (last.bubble === Bubble.TAGS) {
                return cpState;
            }
            const tags = {
                bubble: Bubble.TAGS,
                content: action.content,
            };
            return [...state, tags];
        case HIDE_TAGS:
            const newState = [...state]
            const index = +action.content;
            newState[index] = {...newState[index], hide: true};
            return newState;
        case REMOVE_TAGS:
            return state.filter((s, i)=>{
                return i !== +action.content
            })
        default:
            return state;
    }
}
  