/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';
import {canadianRegions} from './helpers';
import {ENDPOINT_URL, REGIONAL_ENABLED, SEARCH_ENGINE_URL} from './constants';

export default {
    saveResources(resources: any[], others: any[] = []): Promise<any> {
        const formData = new FormData();
        formData.append('resources', JSON.stringify(resources));
        formData.append('others', JSON.stringify(others));
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.post(ENDPOINT_URL+'/resources/save/', formData);
    },
    getResources(): Promise<any> {
        const formData = new FormData();
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.post(ENDPOINT_URL+'/resources/', formData);
    },
    getSource(url: string): Promise<any> {
        return axios.post('/source', {url});
    },
    getAnswers(question: string, method?: string, global_regions?: string[], regionKey?: string): Promise<any> {
        const formData = new FormData();
        formData.append('question', question);
        if (method) formData.append('method', method);
        if (global_regions) formData.append('global_regions', JSON.stringify(global_regions));
        const region = REGIONAL_ENABLED ? regionKey : null;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore: Unreachable code error
        if (canadianRegions[region]) {
            formData.append('region', region);
        }
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.post(ENDPOINT_URL+"/get/", formData);  
    },
    updateAnswer(id: string, update_params: object): Promise<any> {
        const formData = new FormData();
        formData.append('answer_id', id)
        formData.append('update_params', JSON.stringify(update_params))
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.patch(ENDPOINT_URL+"/get/", formData);  
    },
    updateDocument(document_id: string, inc_click_count: boolean = false) {
        const formData = new FormData();
        formData.append('hash_id', document_id)
        formData.append('increment_click_count', JSON.stringify(inc_click_count))
        return axios.patch(SEARCH_ENGINE_URL+"/documents/", formData);  
    },
    getUserProfile(): Promise<any> {
        const token = window.localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': token
            }
        }
        return axios.get(ENDPOINT_URL+"/user-profile/", config);  
    },
    updateUserProfile(update_params: object): Promise<any> {
        const formData = new FormData();
        formData.append('update_params', JSON.stringify(update_params))
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.patch(ENDPOINT_URL+"/user-profile/", formData);  
    },
    setFeedback(answerID: string, useful: boolean): Promise<any> {
        const formData = new FormData();
        formData.append('answer_id', answerID);
        formData.append('useful', useful ? '1' : '0');
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.post(ENDPOINT_URL+"/feedback/", formData);  
    },
    login(email: string,  password: string, remember: boolean, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        if (remember) {
            formData.append('remember', '1');
        }
        formData.append('grToken', token);
        return axios.post(ENDPOINT_URL+"/login/", formData);   
    },
    getSpecs(): Promise<any> {
        return axios.get(ENDPOINT_URL+"/specializations/");
    },
    resend(email: string, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('grToken', token);
        return axios.post(ENDPOINT_URL+"/resend/", formData);   
    },
    verifyEmail(vcode: string, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('vcode', vcode);
        formData.append('grToken', token);
        return axios.post(ENDPOINT_URL+"/confirm-email/", formData);   
    },
    register(firstName: string, lastName: string, email: string, password: string, token: string, orginfo: any): Promise<any> {
        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('orginfo', JSON.stringify(orginfo));
        formData.append('grToken', token);
        return axios.post(ENDPOINT_URL+"/register/", formData);   
    },
    saveToolTip(answerId: string, documentId: string, text: string): Promise<any> {
        const formData = new FormData();
        formData.append('answer_id', answerId);
        formData.append('document_id', documentId);
        formData.append('text', text);
        formData.append('token', window.localStorage.getItem('token')!);
        return axios.post(ENDPOINT_URL+"/save-tooltip/", formData);   
    },
    findAccount(email: string, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('grToken', token);
        return axios.post(ENDPOINT_URL+"/check-account/", formData);   
    },
    verifyCode(email: string, code: string, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('code', code);
        formData.append('grToken', token);
        return axios.post(ENDPOINT_URL+"/verify-code/", formData);   
    },
    saveNewPassword(email: string, code: string, password: string): Promise<any> {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('code', code);
        formData.append('password', password);
        return axios.post(ENDPOINT_URL+"/change-password/", formData);   
    }
}