/* eslint-disable react/prop-types */
import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setActiveMarker} from '../../reducers/chatActions'
import {Modal} from 'react-bootstrap';
import Noty from 'noty';  

import InfoPlus from '../../images/svg/InfoPlus.svg'; 
import './DoctorInfo.css'

import {getGender} from '../../utils/constants';


type DoctorInfoType = {
    data: any;
    idx: number;
    uid: string;
}

const DoctorInfo: React.FC<DoctorInfoType> = ({data, idx, uid}) => {
    const activeIdx = useSelector<any,any>(state=> state.mobile[uid]);
    const [lgShow, setLgShow] = useState<boolean>(false)
    const dispatch = useDispatch();

    const copyToClipBoard = useCallback((text: string)=>{
        // SOURCE: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        const input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
    
        new Noty({
            type: 'info',
            text: 'Copied the number: '+ text,
            timeout: 3000,
        }).show();

    }, [])

    let spcn = "doctorIdx"
    if (activeIdx === idx) {
        spcn = "doctorIdx active"
    }
    
    let fax: any = null
    let faxNumber: any = null
    if (data.fax) {
        fax = data.fax.split(':')
        if (fax.length === 2) {
            fax = fax[1].trim()
        } else {
            fax = data.fax
        }
        faxNumber = fax.match(/\d+/g).map(Number).join('');
    }

    return (
        <div>
            <div className="doctorLeft">
                <div><div className={spcn} onClick={(): void=>dispatch(setActiveMarker(uid, idx))}>{idx+1}</div><h2 className="doctorTitle" data-testid="doctorTitle">{data.first_name} {data.last_name}</h2></div>
                <p className="doctorContent">
                    {data.specializations.join(', ')}<br />
                    {data.spoken_languages.join(', ')}<br />
                    {fax && <span onClick={(): void=>copyToClipBoard(faxNumber)} style={{cursor: 'pointer'}}>F: {fax} <i className="far fa-copy"></i></span>}
                </p>
            </div>
            <div className="doctorRight text-center" data-testid="moreInfoBtn2" onClick={()=>setLgShow(true)}>
                <img src={InfoPlus} alt="more info" className="moreInfoBtn2" />
            </div>
            <div className="clearfix"></div>
            <Modal
                size="lg"
                show={lgShow}
                onHide={(): void => setLgShow(false)}
                className="bottomModal"
            >
                <Modal.Header closeButton>
                    <p className="mdiTitle">
                        {data.first_name} {data.last_name}<br />
                        <span className="mdiSub">{data.specializations.join(', ')}</span>
                    </p>
                </Modal.Header>
                <Modal.Body >
                    <h3 className="midHeader">Summary</h3>
                    <p className="mdiText">
                        {data.former_name && (<><strong>Former Name:</strong> {data.former_name}<br/></>)}
                        <strong>Gender:</strong> {getGender(data.gender)} <br/>
                        <strong>Languages Spoken:</strong> {data.spoken_languages.join(', ')}<br/>
                        <strong>Education:</strong> {data.education}<br/>
                    </p>
                    <h3 className="midHeader">Practice Information</h3>
                    <p className="mdiText">
                        {data.street}<br />
                        {data.phone && (<>{data.phone} <i style={{cursor: 'pointer'}} className="far fa-copy" onClick={(): void=>copyToClipBoard(data.phone.match(/\d+/g).map(Number).join(''))}></i><br /></>)}
                        {data.fax && (<>{data.fax} <i style={{cursor: 'pointer'}} className="far fa-copy" onClick={(): void=>copyToClipBoard(data.fax.match(/\d+/g).map(Number).join(''))}></i><br /></>) }
                    </p>          
                    <h3 className="midHeader">Hospital Previleges</h3>
                    <p className="mdiText">
                        {data.hospitals.map((h: any, i: number)=>{
                            return (<span key={i}>{h.name}, {h.location}<br /></span>)
                        })}
                    </p>
                    <h3 className="midHeader">Specialties</h3>
                    <p className="mdiText" data-testid="mdiText">
                        {data.specializations.join((<br />))}
                    </p>                  
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DoctorInfo;
