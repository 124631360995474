/* eslint-disable react/prop-types */
import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setActiveMarker} from '../../reducers/chatActions'

import {GOOGLE_KEY} from '../../utils/constants';

import './Map.css'

declare let google: any;

type MapType = {
    data: any;
    uid: string;
}

const Map: React.FC<MapType> = ({data, uid}) => {
    const activeIdx = useSelector<any,any>(state=> state.mobile[uid]);
    const [map, setMap] = useState<any>({});
    const mapRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (document.getElementById('gscript')) {
            if (mapRef.current) {
                setMap(new google.maps.Map(mapRef.current, {
                    zoom: 12, 
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false              
                }));       
            }

            return;
        }
        
        const googleScript = document.createElement("script");
        googleScript.id = "gscript"
        googleScript.async = true;
        googleScript.defer = true;
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}`;
        googleScript.onload = (): void => {
            setMap(new google.maps.Map(mapRef.current, {
                zoom: 12, 
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false              
            }));   
        };
        window.document.body.appendChild(googleScript)
    }, [data, mapRef])

    useEffect(()=>{
        if (map.setCenter && map.fitBounds) {
            const mks: any[] = [];
            data.forEach((dr: any, idx: number)=>{
                const point = new google.maps.LatLng(+dr.lat, +dr.lng);
                mks.push(point);
                const marker = new google.maps.Marker({
                    map: map,
                    position: point,
                    label: { color: '#ffffff', fontWeight: 'bold', fontSize: '14px', text: (idx+1).toString() },
                    icon: activeIdx===idx ? '/marker2.png' :'/marker.png'
                }); 
                marker.addListener('click', ()=>{
                    dispatch(setActiveMarker(uid, idx))
                    map.setZoom(18)
                    map.setCenter(point)
                    marker.setIcon('/marker2.png')
                });  
            });  
    
            const bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < mks.length; i++) {
                bounds.extend(mks[i]);
            }
            map.fitBounds(bounds);
        }
    }, [map, data, activeIdx, dispatch, uid])

    return (<div className="mapBox2" ref={mapRef} data-testid="mapBox2"></div>);
}

export default Map;
