/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useEffect} from 'react';

export function useOuterClickNotifier(onOuterClick, innerRef) {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        function handleClick(e) {
            innerRef.current && !innerRef.current.contains(e.target) && onOuterClick(e);
        }
        // only add listener, if the element exists
        if (innerRef.current) {
          document.addEventListener("click", handleClick);
        }
  
        // unmount previous first in case inputs have changed
        return () => document.removeEventListener("click", handleClick);
      },
      [onOuterClick, innerRef] // invoke again, if inputs have changed
    );
}
  