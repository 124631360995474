/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useRef, useCallback, useEffect} from 'react';

import Close from '../../images/svg/Close.svg';
import './DrugDosage.css';

type DoctorsProps = {
    data: any;
}

const DrugDosage: React.FC<DoctorsProps> = ({data}) => {
    const [show, setShow] = useState(false);
    const innerRef = useRef<HTMLDivElement>(null);
    const [answers, setAnswers]  = useState<any[]>([]);
    const [answerSelected, setAnswerSelected] = useState<any>(null);

    useEffect(()=>{
        setAnswers(data.data);
    }, [data])

    const hideModal = useCallback(()=>{
        if (!show || !innerRef.current) return;
        innerRef.current.classList.add("hideModal");
        setTimeout(()=>{
            setShow(false);
            setAnswerSelected(null)
        }, 290)
    }, [show, innerRef, setShow, setAnswerSelected])

    const showAnswer = useCallback((answer)=>{
        setAnswerSelected(answer);
        setShow(true);
    }, [setAnswerSelected])

    return (
        <>
            <div className="drsIntroDiv">
                <h6 className="youAsked">YOU ASKED</h6>
                <h3 className="lsisQuestion">{data.question}</h3>
            </div>

            {answers &&
                <div className="diDiv">
                    <div className="row">
                        {answers.map((answer, idx)=>{
                            return (
                                <div className="col-lg-4" key={idx}>
                                    <div className="diAnswerDiv">
                                        <div className="diAnswer">
                                            <h2 className="diAnswerTitle">{answer.name}</h2>
                                            <button className="btn btn-link" data-testid={"btn-"+idx} onClick={()=>showAnswer(answer)}>Read More &gt;</button>
                                        </div>
                                        <hr className="diHr" />
                                        <p className="diSource">Source: <a href={answer.link} target="_blank" rel="noopener noreferrer">Drugs.com</a></p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }

            {show && 
                <div className="modalBackDrop">
                    <div className="answerModal" ref={innerRef}>
                        <div className="modalContent dosageModal">
                            <h2 className="diAnswerTitle">{answerSelected.name}</h2>
                            {answerSelected.applied_to &&
                                <div className="alert alert-info mb-2">{answerSelected.applied_to}</div>
                            }
                            <div className="mb-2" dangerouslySetInnerHTML={{ __html: answerSelected.content }} />
                            <a href={answerSelected.link} className="my-2 btn btn-sm btn-outline-info" target="_blank" rel="noopener noreferrer">View Source</a>
                        </div>
                        <div className="text-center closeBtn" onClick={hideModal}>
                            <img src={Close} alt="close" />
                            <p className="feedBackBoxTag">Close</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default DrugDosage;
