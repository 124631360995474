import React from 'react';

import Phalogo from '../images/svg/Phalogo.svg';
import UpToDateLogo from '../images/uptodate.png';
import EcdcLogo from '../images/ecdc.png';
import CdcLogo from '../images/cdc.png';
import WhoLogo from '../images/who.png';
import QuebecLogo from '../images/quebec.png';
import CDPHLogo from '../images/cdph.png';
import AlbertaLogo from '../images/alberta.png';
import PHSALogo from '../images/phsa.png';
import BCCDCLogo from '../images/bccdc.png';
import BCGovLogo from '../images/svg/gov_bc_logo.svg';
import BCRALogo from '../images/bcrenalagency-logo.png';
import BCCSULogo from '../images/bccsu-logo.png';
import OntarioLogo from '../images/ontario.png';
import FDALogo from '../images/svg/fda-logo.svg';
import SharedhealthLogo from '../images/sharedhealth-logo.png';
import NovaScotiaLogo from '../images/op3-image-sm02.png';
import CMPALogo from '../images/cmpa.png';
import CNESSTLogo from '../images/cnesst-logo.png';
import MerckManualsLogo from '../images/merck_manuals_logo.png';

import {
    CMPA_square,
    CDC_square,
    ECDC_square,
    CDPH_square,
    FDA_square,
    UpToDate_square,
    PHAC_square,
    JHU_square,
    WHO_square,
    Merck_square,
    CABadge,
    ONBadge,
    WDBadge,
    ABBadge,
    QCBadge,
    BCBadge
} from '../images';

export const globalSources: { [key: string]: any } = {
    'CMPA': {
        'name': 'The Canadian Medical Protective Association',
        'resources': ['CMPA-FAQ'],
        'logo': CMPA_square
    },
    'ECDC': {
        'name': 'European Centre for Disease Control',
        'resources': ['ECDC-FAQ'],
        'logo': ECDC_square,
    },
    'CDC': {
        'name': 'Center for Disease Control',
        'resources': ['CDC-FAQ'],
        'logo': CDC_square
    },
    'WHO': {
        'name': 'World Health Organization',
        'resources': ['WHO-FAQ'],
        'logo': WHO_square
    },
    'CDPH': {
        'name': 'California Department of Public Health',
        'resources': ['CDPH-FAQ'],
        'logo': CDPH_square
    },
    'FDA': {
        'name': 'US Federal Drug Administration',
        'resources': ['FDA-FAQ'],
        'logo': FDA_square
    },
    'UTD': {
        'name': 'UpToDate',
        'resources': ['UpToDate-FAQ', 'UpToDate'],
        'logo': UpToDate_square
    },
    'PHAC': {
        'name': 'Public Health Agency of Canada',
        'resources': ['COVID-PHAC', 'PHAC-COVID-FAQ'],
        'logo': PHAC_square
    },
    'JHU': {
        'name': 'Johns Hopkins University',
        'resources': ['JHU'],
        'logo': JHU_square
    },
    'Merck': {
        'name': 'Merck Manuals',
        'resources': ['Merck'],
        'logo': Merck_square
    },
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getLogo = (answer: any) => {
    switch (answer.resource) {
        case 'CMPA-FAQ':
            return (<><img src={CMPALogo} alt="cmpa logo" className="phalogo" /><span className="lsiCanim">The Canadian Medical Protective Association</span></>)
        case 'ECDC-FAQ':
            return (<><img src={EcdcLogo} alt="ecdc logo" className="phalogo" /><span className="lsiCanim">ECDC</span></>)
        case 'QC-FAQ':
        case 'Quebec-FAQ':
            return (<><img src={QuebecLogo} alt="quebec logo" className="phalogo" /><span className="lsiCanim">Quebec</span></>)
        case 'CDC-FAQ':
            return (<><img src={CdcLogo} alt="cdc logo" className="phalogo" /><span className="lsiCanim">CDC</span></>)
        case 'WHO-FAQ':
            return (<><img src={WhoLogo} alt="who logo" className="phalogo" /><span className="lsiCanim">WHO</span></>)
        case 'ALBERTA-FAQ':
        case 'AB-FAQ':
            return (<><img src={AlbertaLogo} alt="alberta logo" className="phalogo" /><span className="lsiCanim">Alberta</span></>)
        case 'CDPH-FAQ':
            return (<><img src={CDPHLogo} alt="cdph logo" className="phalogo" /><span className="lsiCanim">CDPH</span></>)
        case 'FDA-FAQ':
            return (<><img src={FDALogo} alt="fda logo" className="phalogo" /><span className="lsiCanim">U.S. Food and Drug Administration</span></>)
        case 'NShealth-FAQ':
            return (<><img src={NovaScotiaLogo} alt="Nova Scotia logo" className="phalogo" /><span className="lsiCanim">Nova Scotia</span></>)
        case 'Sharedhealthmb-FAQ':
            return (<><img src={SharedhealthLogo} alt="Shared health logo" className="phalogo" /><span className="lsiCanim">Shared health</span></>)
        case 'UpToDate':
        case 'UpToDate-FAQ':
            return (<><img src={UpToDateLogo} alt="uptodate logo" className="phalogo" /><span className="lsiCanim">UpToDate</span></>)
        case 'COVID-PHAC':
        case 'PHAC-COVID-FAQ':
            return (<img src={Phalogo} alt="public health logo" className="phalogo" />)
        case 'AB_PDF':
        case 'AB_HealthServices':
            return (<><img src={AlbertaLogo} alt="alberta logo" className="phalogo" /><span className="lsiCanim">Alberta</span></>)
        case 'BC_PHSA':
        case 'BC-FAQ':
            return (<><img src={PHSALogo} alt="phsa logo" className="phalogo" /><span className="lsiCanim">PHSA</span></>)
        case 'BCCDC-FAQ':
        case 'BC_BCCDC':
            return (<><img src={BCCDCLogo} alt="BC Center for Disease Control logo" className="phalogo" /><span className="lsiCanim">BC Center for Disease Control</span></>)
        case 'BC_PDF':
            const host = new URL(answer.url);
            switch(host.hostname) {
                case 'www.phsa.ca':
                    return (<><img src={PHSALogo} alt="phsa logo" className="phalogo" /><span className="lsiCanim">PHSA</span></>)
                case 'www2.gov.bc.ca':
                    return (<><img src={BCGovLogo} alt="bc gov logo" className="phalogo" /><span className="lsiCanim">Government of British Columbia</span></>)
                case 'www.bccdc.ca':
                    return (<><img src={BCCDCLogo} alt="BC Center for Disease Control logo" className="phalogo" /><span className="lsiCanim">BC Center for Disease Control</span></>)
                case 'www.bcrenalagency.ca':
                    return (<><img src={BCRALogo} alt="bc renal agency logo" className="phalogo" /><span className="lsiCanim">BC Renal Agency</span></>)
                default:
                    return (<><img src={BCCSULogo} alt="bccsu logo" className="phalogo" /><span className="lsiCanim">British Columbia Centre on Substance Use</span></>)
            }
        case 'QC_PDF':
        case 'QC_General':
            return (<><img src={QuebecLogo} alt="quebec logo" className="phalogo" /><span className="lsiCanim">Quebec</span></>)
        case 'ON_PDF':
        case 'ON-FAQ':
        case 'ON_MOH':
            return (<><img src={OntarioLogo} alt="ontario logo" className="phalogo" /><span className="lsiCanim">Ontario Ministry of Health</span></>)
        case 'CNESST-FAQ':
            return (<><img src={CNESSTLogo} alt="cnesst logo" className="phalogo" /><span className="lsiCanim">Standards, Fairness, Occupational Health and Safety Commission</span></>)
        case 'Merck':
            return (<><img src={MerckManualsLogo} alt="merck logo" className="phalogo" /><span className="lsiCanim">Merck Manuals</span></>)
        default:
            return (<><img src={Phalogo} alt="public health logo" className="phalogo" /><span className="lsiCanim">Canadian Immunization Guide</span></>)
    }
}

interface Regions {
    [key: string]: string;
};

export const regions: Regions = {
    // NL:"Newfoundland", 
    // PE:"Prince Edward Island",
    // NS:"Nova Scotia",
    // NB:"New Brunswick",
    // MB:"Manitoba",
    // SK:"Saskatchewan",
    AB:"Alberta",
    BC:"British Columbia",
    ON:"Ontario",
    QC:"Quebec",
    // YT:"Yukon",
    // NT:"Northwest Territories",
    // NU:"Nunavut",
}

export const canadianRegions: any = {
    'NS': CABadge,
    'MB': CABadge,
    'AB': ABBadge,
    'ON': ONBadge,
    'BC': BCBadge,
    'NB': CABadge,
    'NL': CABadge,
    'SK': CABadge,
    'NT': CABadge,
    'NU': CABadge,
    'YU': CABadge,
    'QC': QCBadge,
    'PE': CABadge
};
const canadianResources = ['COVID-PHAC', 'PHAC-COVID-FAQ', 'CMPA-FAQ'];

export const getBadge = (answer: any) => {
    if (answer.region in canadianRegions) {
        return canadianRegions[answer.region];
    }
    if (canadianResources.includes(answer.resource)) {
        return CABadge;
    }

    return WDBadge;
}