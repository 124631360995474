import {combineReducers} from 'redux';
import auth from './auth';
import chats from './chats';
import result from './result';
import mobile from './mobile';
import limit from './limit';
import global_regions from './global_regions'
import regionKey from './regionKey'

export default combineReducers({
    auth,
    chats,
    result,
    mobile,
    limit,
    global_regions,
    regionKey
})
