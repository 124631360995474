import React from 'react';

import {globalSources} from '../../utils/helpers';
import TagsBubble from '../TagsBubble/TagsBubble'
import {TAGS} from '../../utils/constants'

import './HelpPage.css'

type HelpPageProps = {
    tagClickHandler: Function;
}

// eslint-disable-next-line react/prop-types
const HelpPage: React.FC<HelpPageProps> = ({tagClickHandler}) => {


    return (
        <div className="lsisBox">
            <h6 className="help-title">WHAT CAN TALI DO?</h6>
            <h3 className="help-desc">Here is a guide into what types of questions you can ask Tali</h3>

            <div style={{marginBottom: '24px'}}>
                <TagsBubble content={TAGS} fullpage={true} clickHandler={(tag: string)=>{tagClickHandler(tag)}} />
            </div>

            <h6 className="help-title">WHERE DOES TALI GET IT’S INFORMATION?</h6>
            <div className="row" style={{marginTop: '40px', marginBottom: '15px'}}>
                {Object.keys(globalSources).map((key, i) => (
                    <div className="col-lg-4 d-flex align-items-center mb-4" key={i}>
                        <img src={globalSources[key].logo} alt={globalSources[key].name} className="help-resource-image"/>
                        <p className="help-resource-title">{globalSources[key].name}</p>
                    </div>
                ))}
            </div>

            <h6 className="help-title">WHO IS THIS FOR?</h6>
            <p className="help-answer">Care providers, residents, and medical students who are searching for medically reviewed content.</p>

            <h6 className="help-title">IS TALI OFFICIAL?</h6>
            <p className="help-answer">No, and Tali is not responsible for providing personalized medical advice, diagnosis, or treatment. If you are experiencing a life threatening emergency, please call 911.</p>

            <h6 className="help-title">HOW DO I ENSURE GETTING MORE ACCURATE INFORMATION?</h6>
            <p className="help-answer">When asking questions about medicine try using generic names rather than brand names (ex. Ibuprofen instead of Tylenol). </p>
        </div>
    )
}

export default HelpPage;