/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useRef, useEffect, useLayoutEffect} from 'react';

import {useOuterClickNotifier} from '../../utils/customHooks';
import {getLogo} from '../../utils/helpers';

import Noty from 'noty';  
import request from '../../utils/requests';
import Helpful from '../../images/svg/Helpful.svg';
import Unhelpful from '../../images/svg/Inaccurate.svg';
import Close from '../../images/svg/Close.svg';
import {REGIONAL_ENABLED} from '../../utils/constants';
import requests from '../../utils/requests';

import './Lsis.css';

type LsisProps = {
    data: any;
    qid?: number;
}

type AnswerType = {
    id: string; 
    text: string; 
    url: string; 
    source_clicked: boolean;
    read_more_clicked: boolean;
    'taken-from'?: string;
    document_id?: string;
}

const Lsis: React.FC<LsisProps> = ({data, qid}) => {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState('');
    const [answers, setAnswers]  = useState<any[]>([]);
    const [selectedAnswer, setSelectedAnswer] = useState<AnswerType | null>(null);
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    const [toolTipRect, setToolTipRect] = useState({top: null, left: null});
    const [saving, setSaving] = useState<boolean>(false);
    const [showRegional, setShowRegional] = useState<boolean>(true);
    const [showInCorpus, setShowInCorpus] =  useState<boolean>(true);
    const innerRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    useOuterClickNotifier(() => setShow(false),innerRef);

    useEffect(()=>{
        setAnswers([data.data[0]]);
        setShowRegional(() => {
            return data.data.every((answer: any) => answer.region !== data.regional)
        })
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView();
        }
    }, [data, messagesEndRef])

    const updateAnswer = useCallback((id: string, update_params: any) => {
        const curr_answers = data.data
        const found_answer = curr_answers.find((curr_answer: any) => curr_answer.id === id)
        requests.updateAnswer(id, update_params).then(response => {
            const updated_answer = response.data.data
            if (found_answer.id === updated_answer.id) {
                found_answer.source_clicked = updated_answer.source_clicked
                found_answer.read_more_clicked = updated_answer.read_more_clicked
            }
            setAnswers(curr_answers);
        });
    }, [data.data])

    const readMore = useCallback(async (answer)=>{
        requests.updateDocument(answer.document_id, true)
        setSelectedAnswer(answer);
        const answerUrl = answer.url.split(' ');
        setContent('<p class="text-center"><i class="fa fa-spin fa-spinner fa-2x"></i></p>');
        setShow(true);

        try {
            const res = await request.getSource(answerUrl[0]);

            const data = res.data;
            if (!data.done) throw new Error(data.data);
            setContent(data.data);
            if (!answer.read_more_clicked) {
                updateAnswer(answer.id, {'read_more_clicked': true})
            }

            const takenFroms = answer['taken-from'].split('\n');
            const lastTitle = takenFroms[takenFroms.length - 1].trim();
            const titles = Array.from(document.querySelectorAll('.modalContent h1, .modalContent h2, .modalContent h3, .modalContent h4, .modalContent h5, .modalContent h6, .modalContent span'));
            const title = titles.find(title=>{
                if (title && title.textContent) {
                    return title.textContent.trim() === lastTitle;
                }
                return false;
            });
            if (title) {
                title.classList.add("highlight");
                title.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        } catch(e) {
            setShow(false);
            new Noty({
                type: 'error',
                text: 'Something went wrong, please try again later',
                timeout: 3000,
            }).show();
        }
    }, [setSelectedAnswer, updateAnswer])

    const openSource = useCallback(()=>{
        if (show && selectedAnswer) {
            if (!selectedAnswer.source_clicked) {
                updateAnswer(selectedAnswer.id, {'source_clicked': true})
            }
            window.open(selectedAnswer.url, "_blank");
        }
    }, [show, selectedAnswer, updateAnswer])

    const hideModal = useCallback(()=>{
        if (!show || !innerRef.current) return;

        if (window.getSelection().empty) {  // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {  // Firefox
            window.getSelection().removeAllRanges();
        }
        setShowToolTip(false);

        innerRef.current.classList.add("hideModal");
        setTimeout(()=>{
            setShow(false);
            setSelectedAnswer(null);
        }, 290)
    }, [show, innerRef, setShow, setShowToolTip, setSelectedAnswer])

    const saveFeedBack = useCallback(async (vote: boolean, answer?: AnswerType | null)=>{
        if (!answer) answer = selectedAnswer;
        if (vote) {
            setAnswers(oldAnswers=>{
                return oldAnswers.filter(oa=>{
                    return oa.id === answer!.id
                })
            })
        } else {
            setAnswers(oldAnswers=>{
                return oldAnswers.filter(oa=>{
                    return oa.id !== answer!.id
                })
            })
        }
        try {
            const response = await request.setFeedback(answer!.id, vote);
            if (response.data.done) {
                new Noty({
                    type: 'success',
                    text: 'Your feedback is saved. Thank you',
                    timeout: 3000,
                }).show();
            } else {
                new Noty({
                    type: 'warning',
                    text: response.data.data,
                    timeout: 3000,
                }).show();
            }
        } catch {
            hideModal();
            new Noty({
                type: 'error',
                text: 'Something went wrong, please try again later',
                timeout: 3000,
            }).show();
        }
    }, [selectedAnswer, setAnswers, hideModal])

    const clearCaptureText = useCallback(()=>{
        if (!data.canReportCorrectAnswer) return;
        const selection  = window!.getSelection();
        if (!saving && selection.toString().trim().length < 1) {
            setShowToolTip(false)
        }
    }, [data.canReportCorrectAnswer, saving, setShowToolTip])

    const captureText = useCallback(()=>{
        if (!data.canReportCorrectAnswer) return;
        if (!saving) setShowToolTip(false)
        const selection  = window!.getSelection();
        if (selection.toString().trim().length < 1) return;

        if (!selectedAnswer) {
            let parentElement = selection.anchorNode.parentElement;
            if (parentElement.tagName==='B') {
                parentElement = parentElement.parentElement;
            }
            const documentId = parentElement.getAttribute('data-document');
            if (!documentId) {
                setShowToolTip(false);
                return;
            }
        }

        const rect = selection.getRangeAt(0).getBoundingClientRect();
        setToolTipRect({top: rect.top + rect.height + 5, left: rect.left})
        setShowToolTip(true);
    }, [setToolTipRect, setShowToolTip, saving, data.canReportCorrectAnswer, selectedAnswer])

    useLayoutEffect(() => {
        const handleScroll = (e: any) => {
            captureText();  
        }
    
        window.addEventListener("scroll", handleScroll)
    
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [captureText])

    const saveHandler = useCallback(async (event: any)=>{
        if (saving || !data.canReportCorrectAnswer) return;
        setSaving(true);
        try {
            let documentId = '';
            let answerId = '';
            if (selectedAnswer) {
                documentId = selectedAnswer.document_id;
                answerId = selectedAnswer.id;
            } else {
                let parentElement = window.getSelection().anchorNode.parentElement;
                if (parentElement.tagName==='B') {
                    parentElement = parentElement.parentElement;
                }
                documentId = parentElement.getAttribute('data-document');
                answerId = parentElement.getAttribute('data-answer');
            }

            const text = window!.getSelection()!.toString();
            const response = await request.saveToolTip(answerId, documentId, text)
            const rd = response.data;
            if (!rd.done) throw new Error(rd.data)

            setSaving(false);
            if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
            }
            setShowToolTip(false);

            new Noty({
                type: 'success',
                text: 'Saved Successfully',
                timeout: 3000,
            }).show();
        } catch (e) {
            setSaving(false);
            let msg = e.message;
            if (e.response && e.response.data && e.response.data.data) {
                msg = e.response.data.data;
            }
            new Noty({
                type: 'warning',
                text: msg,
                timeout: 3000,
            }).show();
        }
    }, [saving, setSaving, setShowToolTip, selectedAnswer, data.canReportCorrectAnswer])

    return (
        <>
            {showToolTip && <div className="lsiToolTipe" style={{left: toolTipRect.left+"px", top: toolTipRect.top+"px"}}>
                <button onClick={saveHandler} disabled={saving} className="btn btn-info btn-sm">{saving?(<i className="fa fa-spin fa-spinner"></i>):'Save as correct answer'}</button>
            </div>}
            <div className="lsisBox" ref={messagesEndRef}>
                {REGIONAL_ENABLED && showRegional &&
                    <div className="alert-box mb-2">
                        <div className="row no-gutters">
                            <div className="col-lg-1 d-flex align-items-center">
                                <i className="fas fa-exclamation-circle"></i>
                            </div>
                            <div className="col-lg-10">
                                <span className="mb-4">We couldn&apos;t find any regional content for your question.</span>
                            </div>
                            <div className="col-lg-1">
                                <i className="fa fa-times float-right alert-close" onClick={event=>setShowRegional(false)}></i>
                            </div>
                        </div>
                    </div>
                }
                {!data.inCorpus && showInCorpus &&
                    <div className="alert-box mb-2">
                        <div className="row no-gutters">
                            <div className="col-lg-1 d-flex align-items-center">
                                <i className="fas fa-exclamation-circle"></i>
                            </div>
                            <div className="col-lg-10">
                                <span>The question hasn&apos;t showed up in our repository of evidence-based resources and guidelines yet, but these documents are updated very frequently. Check back again in future.</span>
                            </div>
                            <div className="col-lg-1 d-flex align-items-center justify-content-end">
                                <i className="fa fa-times float-right alert-close" onClick={event=>setShowInCorpus(false)}></i>
                            </div>
                        </div>
                    </div>
                }
                <h6 className="youAsked">YOU ASKED</h6>
                <h3 className="lsisQuestion">{data.question}</h3>
                <div className="lsisAnswersDiv">
                    {Array.isArray(answers) && answers.map((answer, idx)=>{
                        return (
                        <div className="lsiAnswer d-flex align-items-end" key={idx}>
                            <div className="lsiAnswerBox">
                                <div className="lsiAnswerBoxContent">
                                    <p className="lsiTaken">{(answer.type==='faq'? answer.question : answer['taken-from']).split("\n").map((item: string, key: any)=>{
                                        if (key === 0) {
                                            return (<span key={key}><b>{item}</b><br/></span>);
                                        }
                                        return (<span key={key} className="smaller">{key > 0 ? "- ":""}{item}<br/></span>);
                                    })}</p>
                                    <hr />
                                    <div data-document={answer.document_id} data-answer={answer.id} dangerouslySetInnerHTML={{ __html: answer.text}} className="lsiText" onMouseUpCapture={()=>{if (answer.type !=='faq') captureText();}} onMouseMoveCapture={()=>{if (answer.type !=='faq') clearCaptureText()}}></div>
                                    <button className="readMore" data-testid={"btn-"+idx} onClick={()=>{
                                        if (answer.type==='faq' || (answer.resource && answer.resource.includes('PDF'))) {
                                            if (!answer.source_clicked || !answer.read_more_clicked) {
                                                updateAnswer(answer.id, {'source_clicked': true, 'read_more_clicked': true})
                                            }
                                            window.open(answer.url, "_blank"); 
                                        } else {
                                            readMore(answer);
                                        }
                                    }}>Read more &gt;</button>
                                </div>
                                <div className="feedBackBox d-flex align-items-center">
                                    <div className="mr-auto"><p className="usefulText mb-0">Is this useful?</p></div>
                                    <div><button className="feedBackBtn" onClick={()=>saveFeedBack(true,  answer)}>Yes</button></div>
                                    <div><button className="feedBackBtn" onClick={()=>saveFeedBack(false, answer)}>No</button></div>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
            {show && 
            <div className="modalBackDrop">
                <div className="answerModal" ref={innerRef}>
                    <div className="modal-header">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-lg-6 text-left">
                                    <p className="modalHeaderTaken">Taken from:<br />{selectedAnswer['taken-from'].split('\n')[0]}</p>
                                </div>
                                <div className="col-lg-6 text-right">
                                    {getLogo(selectedAnswer)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="modalQuestion">{data.question}</h3>
                    <div className="modalContent" data-testid="modalContent" dangerouslySetInnerHTML={{ __html: content}} onMouseUpCapture={()=>captureText()} onMouseMoveCapture={()=>clearCaptureText()} onScrollCapture={()=>captureText()}></div>
                    <div className="modalSource">
                        <button onClick={openSource} className="modalSourceButton modalSourceButtonPos">Go to source</button>
                    </div>
                    <div className="text-center closeBtn" onClick={hideModal}><img src={Close} alt="close" /><p className="feedBackBoxTag">Close</p></div>
                    <div className="modalButtons">
                        <div className="text-center feedBackBtn" onClick={()=>saveFeedBack(false)}><img src={Helpful} alt="helpful" /><p className="feedBackBoxTag">Helpful</p></div>
                        <div className="text-center feedBackBtn" onClick={()=>saveFeedBack(true)}><img src={Unhelpful} alt="unhelpful" /><p className="feedBackBoxTag">Not helpful</p></div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default Lsis;
