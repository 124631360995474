import requests from '../utils/requests';

export const SET_AUTH = 'SET_AUTH';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_REGION_KEY = 'SET_REGION_KEY';

export const setLimit = (checked: boolean): any => {
    return {
        type: SET_LIMIT,
        checked
    }
}

export const setRegions = (global_regions: any): any => {
    return {
        type: SET_REGIONS,
        global_regions
    }
}

export const setRegionKey = (regionKey: string): any => {
    return {
        type: SET_REGION_KEY,
        regionKey
    }
}

export const updateRegionKey = (regionKey: string, history?: any): any => {
    return async (dispatch: Function) => {
        await requests.updateUserProfile({region: regionKey})
        dispatch(setRegionKey(regionKey))
        if (history) {
            history.replace('/');
        }
    }
}

export const setAuth = (username: string, token: string, expire: any): any => {
    window.localStorage.setItem("username", username);
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("expire", ((new Date()).getTime() + expire).toString());
    return {
        type: SET_AUTH,
        username,
        token,
        expire
    }
}

export const getAuth = (): any => {
    const username  = window.localStorage.getItem("username");
    const token = window.localStorage.getItem("token");
    const expire = window.localStorage.getItem("expire");
    return {
        username,
        token,
        expire
    }
}

export const logout = (): any => {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("expire");
    window.localStorage.removeItem("resources");
    window.localStorage.removeItem("otherResources");
    return {
        type: SET_AUTH,
        username: null,
        token: null,
        expire: null
    }
}