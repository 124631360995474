/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import './TagsBubble.css';

type TagsBubbleProps = {
    content: string[];
    fullpage: boolean;
    addClass?: string;
    clickHandler: Function;
}

const TagsBubble: React.FC<TagsBubbleProps> = ({content, fullpage, addClass, clickHandler}) => {

    const tagsBubbleStyle = {
        animation: addClass ? 'fadeOut 0.5s' : null,
        width: fullpage ? '100%' : '90%',
    } as React.CSSProperties;

    return (
        <div className='tagsBubble' style={tagsBubbleStyle}>
            {content.map((c,i)=>{
                return (<span data-testid={"tagBubble-"+i} className='tagBubble' key={i} onClick={()=>clickHandler(c)}>{c}</span>)
            })}
            <div className="clearfix"></div>
         </div>
    );
}

export default TagsBubble;
