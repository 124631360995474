/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector} from 'react-redux'
import { useHistory, useParams, Link } from "react-router-dom";
import { load } from 'recaptcha-v3'

import requests from '../../utils/requests'
import {SITE_KEY} from '../../utils/constants';

import Logo from '../../images/svg/Logo-light.svg';

import './VerifyEmail.css';

const VerifyEmail: React.FC = () => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [verification, setVerification] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const params = useParams<any>();

    useEffect(()=>{
        if (username) {
            history.replace('/')
        }
        if (params.vcode) {
            setVerification(params.vcode);
            document.getElementsByTagName('button')[0].click();
        }
    }, [username, history, params, setVerification])

    const submitHandler = useCallback(async(event)=>{
        event.preventDefault();

        if (!verification) {
            setError('Please enter verification code');
            return;
        }

        setError('');
        setLoading(true);
        try {
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('resend')   
            const {data} = await requests.verifyEmail(verification, token);
            if (!data.done) throw new Error(data.data);
            setLoading(false);
            setShowForm(false);
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
        }
    }, [verification, setError, setLoading])

    let btnLabel: any = "Verify";
    if (loading) {
        btnLabel = (<i className="fa fa-spin fa-spinner fa-2x"></i>);
    }

    return (
       <div className="registerpage">
            <header className="login-header d-flex flex-row justify-content-center align-items-center">
                <Link to='/'><img src={Logo} className="Logo" alt="Logo" /></Link>
            </header>
            <div className="login-form register-form">
                <h1 className="loginform-title d-flex flex-row justify-content-center align-items-center p-3">Verify Email</h1>
                <hr className="login-divider" />  
                {!showForm && 
                    <>
                        <p className="register-thankyou text-center" style={{marginBottom: 0}}>
                            Thank you, Your e-mail is verified.
                        </p>
                        <div className="form-group pb-4">
                            <button className="loginbtn" onClick={event=>history.replace('/login')}>Log In</button>
                        </div>
                    </>  
                }
                {showForm && 
                    <form onSubmit={submitHandler}>
                        <div className="form-group mt-4">
                            <input type="text" disabled={loading} className="login-formcontrol" value={verification} onChange={event=>setVerification(event.target.value)} required placeholder="Verification Code" /> 
                        </div>
                        <div className="form-group pb-4">
                            <button disabled={loading} type="submit" className="loginbtn">{btnLabel}</button>
                        </div>
                    </form>
                }
                {error && 
                    <div className="alert alert-warning text-center pb-4">{error}</div>
                }
            </div>
        </div>
    );
}

export default VerifyEmail;
