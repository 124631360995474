/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

import {getCategory, getBackgroundColor} from '../../utils/constants';

import './DrugInteraction.css';

type DrugInteractionType = {
    data: any;
    question: string;
}

const DrugInteraction: React.FC<DrugInteractionType> = ({data, question}) => {
    const [lgShow, setLgShow] = useState<boolean>(false)
    
    return (
        <>
            <span className="diType" style={{backgroundColor: getBackgroundColor(data.type)}}>{getCategory(data.type)}</span>
            <p className="diRel">{data.drug} &lt;&gt; {data.name}</p>
            <p className="diApply">{data.apply}</p>
            <span className="diReadMore" data-testid="diReadMore" onClick={(): void=>setLgShow(true)}>READ MORE &gt;</span>
            <p>Source: Drugs.com</p>
            <Modal
                size="lg"
                show={lgShow}
                onHide={(): void => setLgShow(false)}
            >
                <Modal.Header closeButton>
                    <div>
                        <span className="diType" data-testid="diType" style={{backgroundColor: getBackgroundColor(data.type)}}>{getCategory(data.type)}</span>
                        <p className="diRel">{question} &lt;&gt; {data.name}</p>
                        <p className="diApply">{data.apply}</p>
                    </div>
                </Modal.Header>
                <Modal.Body >
                    {data.content.split('\n').map((item: string, key: number) => {
                        return <span key={key}>{item}<br/></span>
                    })}
                    {data.refs && data.refs.length > 0 &&
                        (<><h3 className="diRefs">References</h3><ol>{data.refs.map((r: string, idx: number)=>{return (<li key={idx}>{r}</li>)})}</ol></>)
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DrugInteraction;
