/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector} from 'react-redux'
import { useHistory, useParams, Link } from "react-router-dom";
import { load } from 'recaptcha-v3'

import requests from '../../utils/requests'
import {SITE_KEY} from '../../utils/constants';

import Logo from '../../images/svg/Logo-light.svg';

import './ResendEmail.css';

const ResendEmail: React.FC = () => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const params = useParams<any>();

    useEffect(()=>{
        if (username) {
            history.replace('/')
        }
        if (params.vemail) {
            setEmail(params.vemail);
            document.getElementsByTagName('button')[0].click();
        }
    }, [username, history, params])

    const submitHandler = useCallback(async(event)=>{
        event.preventDefault();

        if (!email) {
            setError('Please enter an email address');
            return;
        }

        setError('');
        setLoading(true);
        try {
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('resend')   
            const {data} = await requests.resend(email, token);
            if (!data.done) throw new Error(data.data);
            setLoading(false);
            setShowForm(false);
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
        }
    }, [email, setError, setLoading])

    let btnLabel: any = "Resend";
    if (loading) {
        btnLabel = (<i className="fa fa-spin fa-spinner fa-2x"></i>);
    }

    return (
       <div className="registerpage">
            <header className="login-header d-flex flex-row justify-content-center align-items-center">
                <Link to='/'><img src={Logo} className="Logo" alt="Logo" /></Link>
            </header>
            <div className="login-form register-form">
                <h1 className="loginform-title d-flex flex-row justify-content-center align-items-center p-3">Resend Verification Email</h1>
                <hr className="login-divider" />  
                {!showForm && 
                    <>
                        <p className="resend-txt">
                            We have sent an email to {email}, please click the link included to verify your email address.
                        </p>
                        <p className="resend-txt-2">
                            Didn&apos;t get the email? <span className="resend-txt-link" onClick={event=>setShowForm(true)}>click here</span> to resend the email.
                        </p>
                    </>
                }   
                {showForm && 
                    <form onSubmit={submitHandler}>
                        <div className="form-group mt-4">
                            <input type="email" disabled={loading} className="login-formcontrol" value={email} onChange={event=>setEmail(event.target.value)} required placeholder="Email" /> 
                        </div>
                        <div className="form-group pb-4">
                            <button disabled={loading} type="submit" className="loginbtn">{btnLabel}</button>
                        </div>
                    </form>
                }
                {error && 
                    <div className="alert alert-warning text-center pb-4">{error}</div>
                }
            </div>
        </div>
    );
}

export default ResendEmail;
