/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux'
import { useHistory, Link } from "react-router-dom";

import requests from '../../utils/requests';

import Logo from '../../images/svg/Logo-light.svg';
import './ChooseResource.css';

const ChooseResource: React.FC = () => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [other, setOther] = useState('');
    const [others, setOthers] = useState<string[]>([]);

    const [resources, setResources] = useState<any[]>([]);
    const [userResources, setUserResources] = useState<any[]>([]);

    const getResources = useCallback(async ()=>{
        setError('')
        setLoading(true)
        try {
            const {data} = await requests.getResources()
            setLoading(false)
            setResources(data.data.gs)
            setUserResources(data.data.ugs)
        } catch (e) {
            setLoading(false)
            setError(e.response ? e.response.data.data : e.message);
        }
    }, [])

    useEffect(()=>{
      if (!username) {
          history.replace('/login')
      }
      getResources();
    }, [username, history, getResources])

    const resourceHandler = useCallback((r: any)=>{
        setUserResources(our=>{
            const fo = our.find(o=>o.rel_id === r.id)
            if (!fo) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                r.is_default = true;
                // eslint-disable-next-line @typescript-eslint/camelcase
                r.rel_id = r.id;
                r.checked = true;
                return [...our, r];
            }
            return our.map(ou=>{
                if (ou.rel_id === r.id) {
                    ou.checked = !ou.checked;
                }
                return ou;
            })
        })
    }, []);

    const userResourceHandler = useCallback((r)=>{
        setUserResources(our=>{
            return our.map(ou=>{
                if (ou.id === r.id) {
                    ou.checked = !ou.checked;
                }
                return ou;
            })
        })
    }, [])

    const isChecked = useCallback((r)=>{
        const ur = userResources.find(u=>{
            return u.rel_id === r.id
        })
        if (ur) return ur.checked;
        return false;
    }, [userResources])

    const addOther = useCallback(()=>{
        if (loading) return; 
        setError('')
        if (other) {
            if (others.indexOf(other) > -1) {
                setError('Duplicate entry!')
                return;
            }
            setOthers((old: any): any=>{
                return [...old, other];
            })
            setOther('')
        }
    }, [other, loading, others])

    const othersHandler = useCallback((index)=>{
        setError('')
        setOthers((old: any): any=>{
            let nr = [...old]
            nr = nr.filter((n,i)=>{
                return i !== index;
            })
            return nr;
        })  
    }, [])

    const submitHandler = useCallback(async(event)=>{
        event.preventDefault()

        if (other) {
            addOther();
            return;
        }

        setError('')
        setLoading(true)

        try {
            const ur = userResources.map(u=>{
                return {
                    id: u.rel_id,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    is_default: u.is_default,
                    checked: u.checked,
                }
            })
            const {data} = await requests.saveResources(ur, others);
            if (!data.done) throw new Error(data.data)
            setLoading(false)
            setError('Your resources saved successfully.');
        } catch(e) {
            setLoading(false)
            setError(e.message);
        }

    }, [other, addOther, userResources, others]);

    let btnLabel: any = "Done";
    if (loading) {
        btnLabel = (<i className="fa fa-spin fa-spinner fa-2x"></i>);
    }

    return (
        <div className="loginpage">
            <header className="login-header d-flex flex-row justify-content-center align-items-center">
                <img src={Logo} className="Logo" alt="Logo" />
            </header>
            <div className="login-form">
                <div className="pt-2">
                    <div className="text-center">
                        <h1 className="cr-title">Choose resources</h1>
                        <h2 className="cr-subtitle">Tali will make shortcuts for you</h2>
                    </div>
                </div>
                <hr className="login-divider" />
                <form className="pb-3" onSubmit={submitHandler}>
                    {resources.map((r, i)=>{
                        return (
                            <div className="form-group mt-3 cr-item" key={i}>
                                <label className="login-checkbox-container">{r.name}
                                    <input type="checkbox" disabled={loading} checked={isChecked(r)} onChange={(): void=>resourceHandler(r)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        )
                    })}
                    {userResources.map((r, i)=>{
                        if (r.is_default) return null;
                        return (
                            <div className="form-group mt-3 cr-item" key={i}>
                                <label className="login-checkbox-container">{r.name}
                                    <input type="checkbox" disabled={loading} checked={r.checked} onChange={(): void=>userResourceHandler(r)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        )
                    })}
                    {others.map((o, i)=>{
                        return (
                            <div className="form-group mt-3 cr-item" key={i}>
                                <label className="login-checkbox-container">{o}
                                    <input type="checkbox" disabled={loading} checked={true} onChange={(): void=>othersHandler(i)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div> 
                        )
                    })}
                    <div className="form-group mb-4 cr-item">
                        <input type="text" placeholder="Other (Please type here)" disabled={loading} className="cr-input" value={other} onChange={(event): void=>setOther(event.target.value)} />
                        <i className="fa fa-plus ml-2" style={{cursor: 'pointer'}} onClick={(): void=>addOther()}></i>
                    </div>
                    <div className="form-group">
                        {error && <div className="text-info text-center p-3">{error}</div>}
                        <button disabled={loading} type="submit" className="loginbtn">{btnLabel}</button>
                    </div>
                    <div className="form-group text-center">
                        <Link to="/resources" className="skipForNow">Skip for now</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChooseResource;
