
// import {TAGS} from '../utils/constants'

export const ADD_QUESTION = 'ADD_QUESTION'
export const ADD_ANSWER = 'ADD_ANSWER'
export const EDIT_ANSWER = 'EDIT_ANSWER'
export const ADD_TAGS = 'ADD_TAGS'
export const REMOVE_TAGS = 'REMOVE_TAGS'
export const HIDE_TAGS = 'HIDE_TAGS'
export const RESET = 'RESET'
export const SET_RESULT = 'SET_RESULT';
export const SET_ACTIVE_MARKER = 'SET_ACTIVE_MARKER'

export const setActiveMarker = (uid: string, idx: number): any => {
    return {
        type: SET_ACTIVE_MARKER,
        uid,
        idx
    }
}

export const setResult = (data: any, timeout?:number): any => {
    if (!timeout) {
        return {
            type: SET_RESULT,
            data, 
        }
    }
    return (dispatch: Function): void => {
        dispatch({
            type: SET_RESULT,
            data: {type: null}, 
        })
        setTimeout(()=>{
            dispatch({
                type: SET_RESULT,
                data, 
            }) 
        }, timeout)
    }
}

export const reset = (): any => {
    return {
        type: RESET
    }
}

export const addQuestion = (content: string): any => {
    return {
        type: ADD_QUESTION,
        content,
    }
}

export const addAnswer = (content: any, timeout?: number): any => {
    if (timeout) {
        return (dispatch: Function): void => {
            setTimeout(()=>{
                dispatch({
                    type: ADD_ANSWER,
                    content,
                });
            }, timeout);
        }
    }
    return {
        type: ADD_ANSWER,
        content,
    }
}

export const addTags = (content: any[], timeout: number): any => {
    return (dispatch: Function): void => {
        setTimeout(()=>{
            dispatch({
                type: ADD_TAGS,
                content,
            });
        }, timeout);
    }
}

export const removeTags = (index: number, timeout: number): any => {
    return (dispatch: Function): void => {
        dispatch({
            type: HIDE_TAGS,
            content: index,
        });
        setTimeout(()=>{
            dispatch({
                type: REMOVE_TAGS,
                content: index,
            });
        }, timeout);
    }
}


export const editAnswer = (content: any, data?: any): any => {
    return (dispatch: Function): void => {
        dispatch({
            type: EDIT_ANSWER,
            content,
            data: data || false,
        })
        if (data) {
            dispatch(setResult(data));
            if (data.type !== "help") {
                dispatch(addAnswer('Would you like to ask another question?', 300));
            }
        } else {
            dispatch(setResult(null));
        }
    }
}