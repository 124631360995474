/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import { load } from 'recaptcha-v3'

import {setAuth} from '../../reducers/actions';
import requests from '../../utils/requests'
import {SITE_KEY} from '../../utils/constants';

import Logo from '../../images/svg/Logo-light.svg';
import './Login.css';

const Login: React.FC = () => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [fieldError, setFieldError] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(()=>{
        if (username) {
            history.replace('/')
        }
    }, [username, history])

    const emailHandler = useCallback(event =>{
        setEmail(event.target.value);
        setError('');
    }, [])

    const passwordHandler = useCallback(event =>{
        setPassword(event.target.value);
        setError('');
    }, [])

    const rememberHandler = useCallback(() => {
        setRemember(old=>!old);
        setError('');
    }, [])

    const submitHandler = useCallback(async(event)=>{
        event.preventDefault();
        setLoading(false);
        setError('');
        if (email === "" || password === "") {
            setError("Please enter a valid email and password.");
            return;
        }
        setLoading(true);
        setFieldError('');
        try {
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('login')
            const {data} = await requests.login(email, password, remember, token);
            dispatch(setAuth(data.email, data.data, data.expire*1000))
            history.replace('/')
            return
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
            if (e.response.data.field) {
                setFieldError(e.response.data.field);
            }
        }
    }, [email, password, remember, history, dispatch])

    let btnLabel: any = "Continue";
    if (loading) {
        btnLabel = (<i className="fa fa-spin fa-spinner fa-2x"></i>);
    }

    let emailClassNames = "login-formcontrol "
    if (error && fieldError === 'email') {
        emailClassNames += "hasError"
    }

    let passwordClassNames = "login-formcontrol "
    if (error && fieldError === 'password') {
        passwordClassNames += "hasError"
    }

    return (
        <div className="loginpage">
            <header className="login-header d-flex flex-row justify-content-center align-items-center">
                <a href="https://tali.ai"><img src={Logo} className="Logo" alt="Logo" /></a>
            </header>
            <div className="login-form">
                <h1 className="loginform-title d-flex flex-row justify-content-center align-items-center p-3">Login</h1>
                <hr className="login-divider" />
                <form onSubmit={submitHandler} autoComplete="off" >
                    <div className="form-group mt-4">
                        <input autoComplete="off" disabled={loading} type="email" className={emailClassNames} required value={email} onChange={emailHandler} placeholder="Email" />
                        {error && fieldError === 'email' && (
                            <div className="fieldError">{error}</div>
                        )}
                    </div>
                    <div className="form-group mt-2">
                        <input autoComplete="off" disabled={loading} type="password" className={passwordClassNames} required value={password} onChange={passwordHandler} placeholder="Enter Password" />
                        {error && fieldError === 'password' && (
                            <div className="fieldError">{error}</div>
                        )}
                    </div>
                    <div className="form-group" style={{paddingLeft:'40px'}}>
                        <label className="login-checkbox-container">Remember Me
                            <input type="checkbox" disabled={loading} checked={remember} onChange={rememberHandler} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="form-group">
                        {error && fieldError==='' && <div className="text-info text-center p-3">{error}</div>}
                        <button disabled={loading} type="submit" className="loginbtn">{btnLabel}</button>
                        <p className="reg-text"><Link to="/register">Don’t have an account? Sign up</Link></p>
                        <p className="reg-text"><Link to="/forgotpassword">Forgot Password?</Link></p>
                    </div>
                    <div className="form-group formlegal">
                        <p>
                            By continuing, you agree to Tali&apos;s <b>Terms of Service, Privacy Policy</b>
                        </p>
                        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;
