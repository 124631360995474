/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import { useSelector} from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import { load } from 'recaptcha-v3'

import requests from '../../utils/requests'
import {SITE_KEY} from '../../utils/constants';

import Logo from '../../images/svg/Logo-light.svg';
import './Register.css';

type Fvalue = {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    role?: string;
    specialty?: string;
    organization?: string;
    organizationTitle?: string;
};

type Fspec = {
    specializations: any[];
    types: any[];
    orgs: any[];
}

const Register: React.FC = () => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const [fvalue, setFvalue] = useState<Fvalue>({});
    const [specs, setSpecs] = useState<Fspec>({specializations:[],types:[],orgs:[]});
    const [emptyFields, setEmptyFields] = useState([]);
    const [showvf, setShowvf] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    useEffect(()=>{
        requests
            .getSpecs()
            .then(({data})=>{
                if (!data.done) throw new Error(data.data);
                setSpecs(data.data)
            })
            .catch(e=>{
                setError('Something went wrong, please refresh the page and try again.')
            })
    }, [])

    useEffect(()=>{
        if (username) {
            history.replace('/')
        }
    }, [username, history])

    const fvalueHandler = useCallback((name: keyof Fvalue, value: string) =>{
        if (name==="confirmPassword" && fvalue.password && fvalue.password !== value) {
            setError('Passwords are not match');
        }
        setFvalue((old: Fvalue): Fvalue=>{
            const newvals = {...old};
            newvals[name] = value;
            return newvals;
        })
        setError('');
    }, [setFvalue, setError, fvalue])

    const submitHandler = useCallback(async(event)=>{
        event.preventDefault();

        const emptyFields = [];
        if (!fvalue.firstName) emptyFields.push('firstName');
        if (!fvalue.lastName) emptyFields.push('lastName');
        if (!fvalue.email) emptyFields.push('email');
        if (!fvalue.password) emptyFields.push('password');
        if (!fvalue.confirmPassword) emptyFields.push('confirmPassword');
        if (!fvalue.role) emptyFields.push('role');
        if (fvalue.role==='Physician' && !fvalue.specialty) emptyFields.push('specialty');
        if (!fvalue.organization) emptyFields.push('organization');
        if (!fvalue.organizationTitle) emptyFields.push('organizationTitle');
        if (emptyFields.length > 0) {
            setEmptyFields(emptyFields);
            return;
        }
        console.log(emptyFields, fvalue)

        if (fvalue.password !== fvalue.confirmPassword) {
            setError('Passwords are not match');
            return;
        }

        setLoading(false);
        setError('');
        setLoading(true);
        try {
            const orginfo = {
                role: fvalue.role,
                specialty: fvalue.specialty,
                organization: fvalue.organization,
                organizationTitle: fvalue.organizationTitle,
            }
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('register')   
            const {data} = await requests.register(fvalue.firstName, fvalue.lastName, fvalue.email, fvalue.password, token, orginfo);
            if (!data.done) throw new Error(data.data);
            setLoading(false);
            setShowvf(true)
        } catch(e) {
            setLoading(false);
            setError(e.response ? e.response.data.data : e.message);
        }
    }, [fvalue, setLoading, setShowvf, setEmptyFields])

    let btnLabel: any = "Create an Account";
    if (loading) {
        btnLabel = (<i className="fa fa-spin fa-spinner fa-2x"></i>);
    }

    return (
        <div className="registerpage">
            <header className="login-header d-flex flex-row justify-content-center align-items-center">
                <a href="https://tali.ai"><img src={Logo} className="Logo" alt="Logo" /></a>
            </header>
            {showvf &&
                <div className="login-form register-form">
                    <h1 className="loginform-title d-flex flex-row justify-content-center align-items-center p-3">Verify Email Address</h1>
                    <hr className="login-divider" />  
                    <p className="register-thankyou">
                        Thank you for signing up! We have sent an email to {fvalue.email}, please click the link included to verify your email address.
                    </p>
                    <p className="register-resend">
                        Didn’t get the email?<br/> 
                        <Link to="/resend">click here</Link> to resend the email.
                    </p>
                </div>
            }
            {!showvf &&
                <div className="login-form register-form">
                    <h1 className="loginform-title d-flex flex-row justify-content-center align-items-center pt-3">Sign Up</h1>
                    <hr className="login-divider" />
                    <form onSubmit={submitHandler} autoComplete="none" noValidate>
                        <div className="form-group my-4 halfrow">
                            <div className="float-left">
                                <input autoComplete="none" disabled={loading} type="text" className={"login-formcontrol halfrow "+(emptyFields.indexOf('firstName')>-1?"field-error":null)} required value={fvalue.firstName  || ''} onChange={event=>fvalueHandler('firstName', event.target.value)} placeholder="First Name" />
                                {emptyFields.indexOf('firstName')>-1 && <span className="text-danger">This field is required</span>}
                            </div>
                            <div className="float-right">
                                <input autoComplete="none" disabled={loading} type="text" className={"login-formcontrol halfrow "+(emptyFields.indexOf('lastName')>-1?"field-error":null)} required value={fvalue.lastName  || ''} onChange={event=>fvalueHandler('lastName', event.target.value)} placeholder="Last Name" />
                                {emptyFields.indexOf('lastName')>-1 && <span className="text-danger">This field is required</span>}
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="form-group mt-4">
                            <input autoComplete="none" disabled={loading} type="email" className={"login-formcontrol "+(emptyFields.indexOf('email')>-1?"field-error":null)} required value={fvalue.email  || ''} onChange={event=>fvalueHandler('email', event.target.value)} placeholder="Email" />
                            {emptyFields.indexOf('email')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                        </div>
                        <div className="form-group mt-2">
                            <input autoComplete="none" disabled={loading} type="password" className={"login-formcontrol "+(emptyFields.indexOf('password')>-1?"field-error":null)} required value={fvalue.password  || ''} onChange={event=>fvalueHandler('password', event.target.value)} placeholder="Password" />
                            {emptyFields.indexOf('password')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                        </div>
                        <div className="form-group mt-2">
                            <input autoComplete="none" disabled={loading} type="password" className={"login-formcontrol "+(emptyFields.indexOf('confirmPassword')>-1?"field-error":null)} required value={fvalue.confirmPassword  || ''} onChange={event=>fvalueHandler('confirmPassword', event.target.value)} placeholder="Confirm Password" />
                            {emptyFields.indexOf('confirmPassword')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                        </div>
                        <div className="form-group mt-2">
                            <select disabled={loading} defaultValue="" required className={"login-formcontrol "+(emptyFields.indexOf('role')>-1?"field-error":null)} value={fvalue.role} onChange={event=>fvalueHandler('role', event.target.value)}>
                                <option value="" disabled>Role</option>
                                {specs.types.map((spec, idx)=>{
                                    if (['Tester','Annotator','Admin'].indexOf(spec[0])>-1) return null;
                                    return (<option value={spec[0]} key={idx}>{spec[1]}</option>)
                                })}
                            </select>
                            {emptyFields.indexOf('role')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                        </div>
                        {fvalue.role === 'Physician' && 
                            <div className="form-group mt-2">
                                <select disabled={loading} defaultValue="" required className={"login-formcontrol "+(emptyFields.indexOf('specialty')>-1?"field-error":null)} value={fvalue.specialty} onChange={event=>fvalueHandler('specialty', event.target.value)}>
                                    <option value="" disabled>Specialty</option>
                                    {specs.specializations.map((spec, idx)=>{
                                        return (<option value={spec[0]} key={idx}>{spec[1]}</option>)
                                    })}
                                </select>
                                {emptyFields.indexOf('specialty')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                            </div>
                        }
                        {fvalue.role && 
                            <div className="form-group mt-2">
                                <select disabled={loading} defaultValue="" required className={"login-formcontrol "+(emptyFields.indexOf('organization')>-1?"field-error":null)} value={fvalue.organization} onChange={event=>fvalueHandler('organization', event.target.value)}>
                                    <option value="" disabled>Organization</option>
                                    {specs.orgs.map((spec, idx)=>{
                                        return (<option value={spec[0]} key={idx}>{spec[1]}</option>)
                                    })}
                                </select>
                                {emptyFields.indexOf('organization')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                            </div>
                        }
                        {fvalue.role && fvalue.organization &&
                            <div className="form-group mt-2">
                                <input autoComplete="none" disabled={loading} type="text" className={"login-formcontrol "+(emptyFields.indexOf('organizationTitle')>-1?"field-error":null)} required value={fvalue.organizationTitle || ''} onChange={event=>fvalueHandler('organizationTitle', event.target.value)} placeholder={fvalue.organization==='Other' ? 'Specify Other' :'Name of the organization'} />
                                {emptyFields.indexOf('organizationTitle')>-1 && <span className="text-danger field-error-message">This field is required</span>}
                            </div>
                        }

                        <div className="form-group">
                            <p className="required-text">* Indicates a required field</p>
                            {error && <div className="text-info text-center p-3">{error}</div>}
                            <button disabled={loading} type="submit" className="loginbtn">{btnLabel}</button>
                            <p className="reg-text"><Link to="/login">Do you already have an account? Log In</Link></p>
                        </div>
                        <div className="form-group formlegal">
                            <p>
                                By continuing, you agree to Tali&apos;s <b>Terms of Service, Privacy Policy</b>
                            </p>
                            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

export default Register;
