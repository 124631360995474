/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from 'react';
import { useHistory } from "react-router-dom";

import Noty from 'noty';  
import requests from '../../utils/requests';

import './Resources.css';

type ResourcesProps = {
    link?: string;
}

const Resources: React.FC<ResourcesProps> = ({link}) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false)
    const [resources, setResources] = useState([]);
    const history = useHistory();

    const getResources = useCallback(async ()=>{
        setLoading(true)
        try {
            const {data} = await requests.getResources()
            setLoading(false)
            setResources(data.data.ugs)
        } catch (e) {
            setLoading(false)
            new Noty({
                type: 'warning',
                text: e.response ? e.response.data.data : e.message,
                timeout: 3000,
            }).show();
        }
    }, [setLoading, setResources])

    useEffect(()=>{
        if (link) {
            setUrl(link);
        } else {
            getResources();
            setUrl('');
        }
    }, [link, getResources])

    if (url) {
        return (<iframe className="resourceFrame" title="resource" src={url} />);
    }

    if (loading) {
        return (<p className="text-center p-4" data-testid="spinner"><i className="fa fa-spin fa-spinner fa-2x"></i></p>);
    }

    return (
        <div className="resources">
            <h1 className="resourcesTitle">Resources <i className="fa fa-cog" style={{cursor: 'pointer'}} onClick={(): void=>{history.replace('/choose/resource')}}></i></h1>
            <div className="rboxs">
                {resources.map((resource: any, idx: number)=>{
                    if (!resource.is_active) return null;
                    return (
                        <div key={idx} className="rbox" onClick={(): void=>setUrl(resource.url)}>
                            <div className="row">
                                <div className="col-lg-2 rboxImage">
                                    <img src={resource.logo} alt={resource.name} />
                                </div>
                                <div className="col-lg-10">
                                    <h6 className="rboxTitle" data-testid={"rboxTitle-"+idx}>{resource.name}</h6>
                                    <p className="rboxInfo" data-testid={"rboxInfo-"+idx}>{resource.description}</p>
                                </div>
                            </div>
                        </div>
                    )
                })} 
            </div>
        </div>
    );
}

export default Resources;
